import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const selectAppState = createSelector(
  fromFeature.selectAppState,
  ({ appState }) => appState,
);

export const selectHandset = createSelector(
  selectAppState,
  ({ handset }) => handset,
);

export const selectWelcomeScreen = createSelector(
  selectAppState,
  ({ welcome }) => welcome,
);

export const selectFilter = createSelector(
  selectAppState,
  ({ filter }) => filter,
);

export const selectSkip = createSelector(
  selectAppState,
  ({ skip }) => skip,
);

export const selectNewTask = createSelector(
  selectAppState,
  ({ newTask }) => newTask,
);

export const selectNewTaskButton = createSelector(
  selectAppState,
  ({ newTask, newTaskButton }) => newTask && newTaskButton,
);

export const selectNewTaskComponent = createSelector(
  selectAppState,
  ({ newTask, newTaskComponent }) => newTask && newTaskComponent,
);

export const selectScannedCode = createSelector(
  selectAppState,
  ({ scannedCode }) => ({ scannedCode }),
);

export const selectLoading = createSelector(
  selectAppState,
  ({ loading }) => loading,
);
