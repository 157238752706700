import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../core/shared/material.module';

import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { TasksModule } from './tasks/tasks.module';

@NgModule({
  declarations: [FooterMenuComponent],
  exports: [SidenavModule, TasksModule, FooterMenuComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule
  ]
})
export class BodyModule {}
