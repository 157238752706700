import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '@circlon/angular-tree-component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@core/shared/material.module';
import { HighlightPipe } from '@core/shared/pipes/highlight.pipe';
import { ObjectStructureComponent } from './object-structure/object-structure.component';
import { SearchListItemComponent } from './search-list-item/search-list-item.component';
import { SearchListComponent } from './search-list/search-list.component';
import { SearchComponent } from './search/search.component';
import { SidenavComponent } from './sidenav/sidenav.component';

@NgModule({
  declarations: [
    HighlightPipe,
    ObjectStructureComponent,
    SearchComponent,
    SearchListComponent,
    SearchListItemComponent,
    SidenavComponent,
  ],
  exports: [
    HighlightPipe,
    SidenavComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    TreeModule,
  ],
})
export class SidenavModule {
}
