import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-room-input',
  styleUrls: ['./room-input.component.scss'],
  templateUrl: './room-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomInputComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() height?: string;
  @Input() type?: string;
  @Input() room?: string;
  @Input() controlName?: string;

  constructor(public controlContainer: ControlContainer) {
  }

  get formGroup() {
    return this.controlContainer.control as FormGroup;
  }
}
