import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ObjectNode } from '@models/object-node';

@Injectable({
  providedIn: 'root',
})
export class NodeBackgroundService {
  private backgroundColor = '#EDF6F6';

  getNodeColor(currentNode: ObjectNode, nodes: ObjectNode[]) {
    if(environment.isDaimler) {
      return this.daimlerColor(currentNode, this.flatten(nodes));
    }
    return this.defaultColor(currentNode);
  }

  private defaultColor(currentNode: ObjectNode) {
    if(currentNode.underBifmMinSirLevel) {
      return this.backgroundColor;
    }
    return undefined;
  }

  private daimlerColor(currentNode: ObjectNode, nodes: ObjectNode[]) {
    if(currentNode.attributes === 'T') {
      return undefined;
    }
    while(currentNode.parentId) {
      currentNode = nodes.find(f => f.id === currentNode.parentId);
      if(!currentNode) {
        return undefined;
      }
      if(currentNode?.attributes === 'T') {
        return this.backgroundColor;
      }
    }
    return undefined;
  }

  private flatten(nodes: ObjectNode[], result: ObjectNode[] = []) {
    nodes.forEach(node => {
      result.push(node);
      if(node.children) {
        this.flatten(node.children, result);
      }
    });
    return result;
  }
}
