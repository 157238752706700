import { AppSettingsApiEffects } from './app-settings-api.effects';
import { AppStateEffects } from './app-state.effects';
import { ObjectStructureEffects } from './object-structure.effects';
import { TaskEffects } from './task.effects';
import { TreeNodeSearchApiEffects } from './tree-node-search-api.effects';
import { TreeNodeSearchPageEffects } from './tree-node-search-page.effects';
import { UserEffects } from './user.effects';

export const effects: any[] = [
  AppSettingsApiEffects,
  AppStateEffects,
  ObjectStructureEffects,
  TaskEffects,
  TreeNodeSearchApiEffects,
  TreeNodeSearchPageEffects,
  UserEffects,
];
