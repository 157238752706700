import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppStateService } from '@services/app-state.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  delay = 750;
  counter = 0;
  isLoading = false;

  constructor(private store: Store, private appStateService: AppStateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.counter += 1;
    if (this.counter > 0 && !this.isLoading) {
      this.isLoading = true;
      this.appStateService.loading(true);
    }

    return next.handle(req)
      .pipe(
        finalize(() => {
          this.counter -= 1;
          if (this.counter === 0) {
            this.isLoading = false;
            setTimeout(() => this.appStateService.loading(false), this.delay);
          }
        }),
      );
  }

  /*
  delay = 750;
  nextLoad = Date.now();
  isTimedOut = false;
  isLoading = false;

  constructor(private store: Store, private appStateService: AppStateService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      finalize(() => {
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        this.appStateService.loading(true);
      }),
      tap(() => {
        if (Date.now() <= this.nextLoad || this.isTimedOut) {
          return;
        }

        this.isTimedOut = true;
        this.nextLoad = Date.now() + this.delay;
        setTimeout(() => {
          this.isLoading = false;
          this.isTimedOut = false;
          // https://medium.com/p/f0b37e244ccb
          Promise.resolve(null).then(() => this.appStateService.loading(false));
        }, this.delay);
      }),
    );
  }
  */

  /*
    // https://medium.com/grensesnittet/loading-status-in-angular-done-right-aeed09cfbea6
    private requests: HttpRequest<any>[] = [];
    private loading = false;

    constructor(private store: Store, private notificationService: NotificationService) {
    }

    removeRequest(req: HttpRequest<any>) {
      const i = this.requests.indexOf(req);
      if(i >= 0) {
        this.requests.splice(i, 1);
      } else if(this.loading) {
        this.store.dispatch(AppStateActions.loading({ loading: false }));
        this.loading = false;
      }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.requests.push(req);

      if(!this.loading) {
        this.store.dispatch(AppStateActions.loading({ loading: true }));
        this.loading = true;
      }

      // We create a new observable which we return instead of the original
      return new Observable((observer: any) => {
        // And subscribe to the original observable to ensure the HttpRequest is made
        const subscription = next.handle(req).subscribe(
          event => {
            if(event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            // console.error(err)
            // debugger

            let full = '';
            if(err?.status === 404) {
              full = err?.status
                ? `${full}Status: ${err.status}\r\n`
                : full;
              full = err?.name
                ? `${full}Name: ${err.name}\r\n`
                : full;
              full = err?.message
                ? `${full}Message: ${err.message}\r\n`
                : full;
              full = err?.error
                ? `${full}Error: ${err.error}\r\n`
                : full;
            } else {
              full = err?.status
                ? `${full}Status: ${err.status}\r\n`
                : full;
              full = err?.statusText
                ? `${full}Status Text: ${err.statusText}\r\n`
                : full;
              full = err?.url
                ? `${full}URL: ${err.url}\r\n`
                : full;
              full = err?.error?.message
                ? `${full}Message: ${err.error.message}\r\n`
                : full;
              full = err?.error?.exceptionType
                ? `${full}Exception Type: ${err.error.exceptionType}\r\n`
                : full;
              full = err?.error?.exceptionMessage
                ? `${full}Exception Message: ${err.error.exceptionMessage}\r\n`
                : full;
            }

            this.notificationService.error(full);
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          },
        );
        // return teardown logic in case of cancelled requests
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }
  */
}
