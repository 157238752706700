import { createReducer, on } from '@ngrx/store';
import { environment, Environment } from '@environments/environment';
import { AppSettings, Device, EnumBase, Team } from '../../core/models';
import { AppSettingsActions } from '../../store/actions';

export const featureKey = 'appSettings';

export interface State {
  appSettings: AppSettings;
  environment: Environment;
  device: Device;
  enums: {
    damagePictures: EnumBase[];
    divisions: EnumBase[];
    orderTypes: EnumBase[];
    teamGroups: Team[];
    teams: Team[];
    trades: EnumBase[];
    loading: boolean;
    loaded: boolean;
    error: string;
  };
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  environment,
  appSettings: undefined,
  device: undefined,
  enums: {
    damagePictures: [],
    divisions: [],
    orderTypes: [],
    teamGroups: [],
    teams: [],
    trades: [],
    loading: false,
    loaded: false,
    error: null,
  },
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(AppSettingsActions.loadSettings, (state): State => ({
    ...state,
    loading: true,
  })),
  on(AppSettingsActions.loadSettingsSuccess, (state, { appSettings }): State => ({
    ...state,
    appSettings,
    loading: false,
    loaded: true,
  })),
  on(AppSettingsActions.loadSettingsFail, (state): State => ({
    ...state,
    loading: false,
  })),
  on(AppSettingsActions.loadDeviceSuccess, (state, { device }): State => ({
    ...state,
    device,
  })),
  on(AppSettingsActions.loadSettingsFail, (state): State => ({
    ...state,
    device: undefined,
  })),
  on(AppSettingsActions.loadEnums, (state): State => ({
    ...state, enums: { ...state.enums, loading: true, loaded: false },
  })),
  on(AppSettingsActions.loadEnumsSuccess, (state, action): State => ({
    ...state, enums: { ...action, error: null, loading: false, loaded: true },
  })),
  on(AppSettingsActions.loadEnumsFail, (state): State => ({
    ...state, enums: { ...state.enums, loading: false, loaded: false },
  })),
);
