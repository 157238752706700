import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { environment } from '@environments/environment';
import { AppStateActions, TaskActions } from '@store/index';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import * as fromTasks from '@store/selectors/task.selectors';

@Component({
  selector: 'app-task-pager',
  styleUrls: ['./task-pager.component.scss'],
  templateUrl: './task-pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPagerComponent {
  @ViewChild(MatPaginator, { static: false }) paginator?: MatPaginator;
  taskCount$ = this.store.select(fromTasks.selectCount);
  activeNode$ = this.store.select(fromObjectStructure.selectActiveNode);
  pageSize: number;
  version: string;

  constructor(private store: Store) {
    this.pageSize = environment.pageSizeDefault;
    this.version = environment.version;
  }

  hidden(count) {
    return count && count < this.pageSize;
  }

  pageChangeEvent({ previousPageIndex, pageIndex, pageSize }: any, node) {
    // todo: fix error with filter of tasks that not on the first page

    if(node && previousPageIndex !== pageIndex) {
      this.store.dispatch(AppStateActions.skipTasks({ skip: pageSize * pageIndex }));
      this.store.dispatch(TaskActions.loadTasks());
    }
  }
}
