import { createReducer, on } from '@ngrx/store';
import { User } from '@models/user';
import { TreeNodeSearchPageActions, UserActions } from '../../store/actions';

export const featureKey = 'user';

export interface State {
  user: User;
  logged: boolean;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  user: new User(),
  logged: false,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state): State => ({
    ...state,
    loading: true,
  })),
  on(UserActions.loadUserSuccess, (state, { user }): State => ({
    ...state,
    user,
    loading: false,
    loaded: true,
  })),
  on(UserActions.loadUserFail, (state): State => ({
    ...state,
    loading: false,
  })),
  on(UserActions.loginSuccess, (state): State => ({
    ...state,
    logged: true,
  })),
  on(UserActions.loginFail, (state): State => ({
    ...state,
    logged: false,
  })),
  on(TreeNodeSearchPageActions.selectSearchNode, (state, { id }): State => ({
    ...state,
    user: { ...state.user, officeNode: id },
  })),
);
