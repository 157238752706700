<!--suppress JSRemoveUnnecessaryParentheses -->
<ng-container *ngIf="{count: (taskCount$ | async), node: (activeNode$ | async)} as ctx">
  <div [hidden]="hidden(ctx.count)" class="mat-pager">
    <div class="version d-flex flex-row align-items-center">
      <div class="ms-3">{{version}}</div>
      <mat-paginator (page)="pageChangeEvent($event, ctx.node)"
        [hidePageSize]="true"
        [length]="ctx.count"
        [pageSize]="pageSize"
        [showFirstLastButtons]="true"
        class="ms-auto"
      >
      </mat-paginator>
    </div>
  </div>
</ng-container>
