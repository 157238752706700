import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeModule } from '@circlon/angular-tree-component';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor';
import { WinAuthInterceptor } from '@core/interceptors/win-auth.interceptor';
import { LoaderComponent } from '@core/shared/loader/loader.component';
import { MaterialModule } from '@core/shared/material.module';
import { environment } from '@environments/environment';
import { AppStoreModule } from '@store/app-store.module';
import { metaReducers } from '@store/reducers';
import { AppComponent } from './app.component';
import { reducers } from './reducers';
import { BodyModule } from './ui/body/body.module';
import { PaginatorIntl } from './ui/body/tasks/task-pager/paginator-intl';
import { HeaderModule } from './ui/header/header.module';
import { LoginComponent } from './ui/login/login.component';
import { ScannerDialogComponent } from './ui/scanner-dialog/scanner-dialog.component';
import { ScannerComponent } from './ui/scanner/scanner.component';
import { WelcomeScreenComponent } from './ui/welcome-screen/welcome-screen.component';

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    LoaderComponent,
    LoginComponent,
    ScannerComponent,
    ScannerDialogComponent,
    WelcomeScreenComponent,
  ],
  exports: [LoaderComponent],
  entryComponents: [ScannerDialogComponent],
  imports: [
    AppStoreModule,
    BodyModule,
    BrowserAnimationsModule,
    BrowserModule,
    HeaderModule,
    HttpClientModule,
    LayoutModule,
    MaterialModule,
    [TreeModule],
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
      },
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'diva-note',
      maxAge: 25,
      logOnly: environment.production,
    }),
    // for Daimler build - comment
    /*
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    */
    ReactiveComponentModule,
    ReactiveFormsModule,
  ],
  providers: [
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor },
    // conflict with MsalInterceptor
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: WinAuthInterceptor,
    },
    {
      deps: [TranslateService],
      provide: MatPaginatorIntl,
      useFactory: (translateService: TranslateService) => new PaginatorIntl(translateService).getPaginatorIntl(),
    },
    CookieService,
  ],
})
export class AppModule {
}

// https://www.youtube.com/watch?v=TkCKqeYjpv0
// MSAL example
