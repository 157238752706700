<div class="header-toolbar">
  <div class="header-toolbar__filler_left"></div>
  <div (click)="onClick()" class="header-toolbar__logo">
    <img src="../../../../assets/img/logo-mobile.png"/>
  </div>
  <div class="header-toolbar__filler_right"></div>
  <div class="header-toolbar__divider"></div>
  <div class="header-toolbar__bell" matBadge="1" matBadgeColor="warn">
    <svg height="44" viewBox="0 0 24 20" width="44">
      <defs>
        <path
          d="M12.001 21.75c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-4.5 3.24-4.5 6.32v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.89 0 1.34-1.08.71-1.71l-1.29-1.29z"
          id="a"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <mask fill="#fff" id="b">
          <use xlink:href="#a"/>
        </mask>
        <g fill="#000" fill-opacity=".54" mask="url(#b)">
          <path d="M0 0h24v24H0z"/>
        </g>
      </g>
    </svg>
  </div>
  <ng-container *ngIf="{ handset: handset$ | async } as c">
    <div *ngIf="!c.handset" class="header-toolbar__divider"></div>
    <app-user *ngIf="!c.handset" class="header-toolbar__user"></app-user>
    <div *ngIf="c.handset" class="header-toolbar__divider"></div>
    <div *ngIf="c.handset && scanner" class="header-toolbar__scanner d-flex me-3">
      <mat-icon class="align-self-center" (click)="buttonClick.emit({ selection: 'scanner' })">qr_code_scanner</mat-icon>
    </div>
    <div *ngIf="c.handset" class="header-toolbar__filler_last"></div>
    <div class="header-toolbar__menu">
      <mat-icon>more_vert</mat-icon>
    </div>
  </ng-container>
</div>
