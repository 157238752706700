import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TreeNodeSearchPageActions } from '@store/index';
import * as fromSelectors from '@store/selectors/tree-node-search.selectors';

@Component({
  selector: 'app-search',
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  searchTerm$ = this.store.select(fromSelectors.selectSearchFilter);
  searchOption:string;
  constructor(private store: Store) {
  }

  onFilterKeyUp(searchFilter: string, searchOption: string) {
    if(searchFilter !== '' && searchFilter !== null) {
      this.store.dispatch(TreeNodeSearchPageActions.load({ term: searchFilter,searchOption: searchOption }));
    } else {
      this.store.dispatch(TreeNodeSearchPageActions.init());
    }
  }

  onClick() {
    this.store.dispatch(TreeNodeSearchPageActions.init());
  }
}
