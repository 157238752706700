<div class="d-flex flex-column">

  <!--Header-->
  <div class="header__text mt-5 mx-4">{{ 'WELCOME.HEADER' | translate }}</div>

  <!--Scanner button-->
  <button
    (click)="buttonClick.emit({ selection: 'scanner' })"
    class="mt-3 mx-4 d-flex flex-row align-items-center"
    mat-raised-button
    style="height: 6rem"
  >
    <div class="d-inline-block ms-2 me-4">
      <mat-icon class="align-middle">qr_code_scanner</mat-icon>
    </div>

    <div class="button__text align-middle d-inline-block">{{ 'WELCOME.SCANNER' | translate | uppercase }}</div>
  </button>

  <!--Object Structure button-->
  <button
    (click)="buttonClick.emit({ selection: 'tree-node' })"
    class="mt-3 mx-4 d-flex flex-row align-items-center"
    mat-raised-button
    style="height: 6rem;"
  >
    <div class="d-inline-block ms-2 me-4">
      <mat-icon class="align-middle">share</mat-icon>
    </div>

    <div class="button__text align-middle d-inline-block">{{ 'WELCOME.OBJECT_STRUCTURE' | translate | uppercase }}</div>
  </button>
</div>
