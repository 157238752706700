<!--suppress JSRemoveUnnecessaryParentheses -->
<mat-card [ngClass]="task?.id === (newTaskCreated$ | async) ? 'highlighted' : 'ordinal'">
  <mat-card-content>
    <div class="task__main">
      <svg style="position: absolute; width: 0; height: 0; overflow: hidden">
        <defs>
          <symbol id="icon-warning" viewBox="0 0 32 32">
            <title>warning</title>
            <path
              d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z"
            ></path>
            <path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path>
            <path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z"></path>
          </symbol>
        </defs>
      </svg>

      <div class="task__id">
        <svg
          *ngIf="task?.underBottleNeck"
          class="task__icon-warning"
          matTooltip="{{ 'TASK_PRIORITY.TOOLTIP' | translate }}"
        >
          <use xlink:href="#icon-warning"></use>
        </svg>
        {{ 'TASK.ID' | translate | uppercase }}: {{ task?.code }}
      </div>

      <mat-icon
        *ngIf="task?.atWork"
        class="task__icon at-work"
        matTooltip="{{ 'TASK_FILTER.AT_WORK' | translate }}"
        matTooltipPosition="left"
      >
        assignment_turned_in
      </mat-icon>

      <mat-icon
        *ngIf="!task?.atWork"
        class="task__icon not-at-work"
        matTooltip="{{ 'TASK_FILTER.NOT_AT_WORK' | translate }}"
        matTooltipPosition="left"
      >
        assignment_late
      </mat-icon>

      <div class="task__create-date">
        {{ 'TASK.OPENED' | translate | uppercase }}: {{ task?.creationDate | date: 'dd.MM.yyyy' }}
      </div>

      <div class="task__node-path">{{ task?.path }}</div>

      <div (click)="toggleDetails()" class="task__title">
        <span>{{ task?.designation }}</span>
      </div>

      <div class="task__toggle-details">
        <button (click)="toggleDetails()" mat-icon-button>
          <mat-icon>{{ isDetailed ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </div>

      <div class="task__separator"></div>

      <div class="task__order-type-name">{{ 'TASK.ORDER_TYPE' | translate | uppercase }}</div>

      <div class="task__damage-picture-name">{{ 'TASK.DAMAGE_PICTURE' | translate | uppercase }}</div>

      <ng-container *ngIf="{  damagePictures: damagePictures$ | async, orderTypes: orderTypes$ | async } as c">

        <div class="task__order-type-content word-break">
          <span>{{ getOrderType(c.orderTypes, task?.orderTypeId) }}</span>
        </div>

        <div class="task__damage-picture-content word-break">
          <span>{{ getDamagePicture(c.damagePictures, task?.damagePictureId) }}</span>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isDetailed">
      <app-task-card-details [standalone]="standalone" [task]="task"></app-task-card-details>
    </div>
  </mat-card-content>
</mat-card>
