import { MediaDataEntry } from './media-data-entry';

export class Task {
  damagePictureId?: number;
  damagePictureDescription?: string;
  mainGroupId?: number;
  orderTypeId?: number;
  subGroupId?: number;
  treeNodeId?: number;
  building?: string;
  room?: string;
  detectorName?: string;

  id?: number;
  creationDate?: Date;
  detectorBifmPhone?: string;
  detectorBusinessPhone?: string;
  detectorMobilePhone?: string;
  atWork?: boolean;
  mediaFiles?: MediaDataEntry[];
  underBottleNeck?: boolean;

  // todo?: fix errors here
  code?: string;
  designation?: string;

  path?: string;
}
