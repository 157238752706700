/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

const scriptId = 'qr-scanner';
const scriptPath = 'assets/qr-scanner.js';
const windowVariableStopScanning = 'stopScanning';
const windowVariableScannedCode = 'scannedCode';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerComponent implements AfterViewInit, OnDestroy {
  stopped = false;

  @Output() readonly code = new EventEmitter<string>();

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document2: Document) {
  }

  ngAfterViewInit() {
    // @ts-ignore
    window[windowVariableStopScanning] = false;
    const script = this.renderer2.createElement('script');
    script.id = scriptId;
    script.src = scriptPath;
    this.renderer2.appendChild(this.document2.body, script);
  }

  ngOnDestroy() {
    const script = document.getElementById(scriptId);
    if(script && script.parentNode) {
      script.parentNode.removeChild(script);
    }
  }

  stopScanning() {
    // @ts-ignore
    window[windowVariableStopScanning] = true;
    this.stopped = true;
  }

  onCodeDetected() {
    // @ts-ignore
    this.code.emit(window[windowVariableScannedCode]);
  }
}
