import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { ObjectStructureService } from '@services/object-structure.service';
import { TreeNodeSearchApiActions } from '../actions';
import * as fromSelectors from '../selectors/tree-node-search.selectors';

@Injectable({providedIn: 'root'})
export class TreeNodeSearchApiEffects {
  search$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchApiActions.search),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.select(fromSelectors.selectSearchTerm),
        this.store.select(fromSelectors.selectFilterOptions),
      ),
    )),
    switchMap(([, term, options]) => this.objectStructureService.search(term, options).pipe(
      map(treeNodes => TreeNodeSearchApiActions.searchSuccess({ treeNodes })),
      catchError(error => of(TreeNodeSearchApiActions.searchFail({ error }))),
    )),
  ));

  searchMore$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchApiActions.searchMore),
    concatMap(action => of(action).pipe(
      withLatestFrom(
        this.store.select(fromSelectors.selectSearchTerm),
        this.store.select(fromSelectors.selectMoreFilterOptions),
      ),
    )),
    switchMap(([, term, options]) => this.objectStructureService.search(term, options).pipe(
      map(treeNodes => TreeNodeSearchApiActions.searchMoreSuccess({ treeNodes })),
      catchError(error => of(TreeNodeSearchApiActions.searchMoreFail({ error }))),
    )),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      TreeNodeSearchApiActions.searchFail,
      TreeNodeSearchApiActions.searchMoreFail,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private objectStructureService: ObjectStructureService,
    private store: Store,
  ) {
  }
}
