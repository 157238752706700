import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { ObjectStructureService } from '@services/object-structure.service';
import * as fromSelectors from '@store/selectors/app-settings.selectors';
import * as fromObjectStructureSelectors from '@store/selectors/object-structure.selectors';
import { AppStateActions, ObjectStructureActions, TaskActions } from '../actions/';

@Injectable({ providedIn: 'root' })
export class ObjectStructureEffects {
  loadNode$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadNode),
    switchMap(({ id }) => this.objectStructureService.getTreeNode(id).pipe(
      map(node => ObjectStructureActions.loadNodeSuccess({ node })),
      catchError(error => of(ObjectStructureActions.loadNodeFail({ error }))),
    )),
  ));

  loadNodeChildren$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadNodeChildren),
    switchMap(({ id }) => this.objectStructureService.getRoot(id).pipe(
      map(nodes => ObjectStructureActions.loadNodeChildrenSuccess({ nodes })),
      catchError(error => of(ObjectStructureActions.loadNodeChildrenFail({ error }))),
    )),
  ));

  loadOfficeNode$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadOfficeNode),
    concatLatestFrom(() => this.store.select(fromSelectors.selectBifmNodeId)),
    filter(([, id]) => id !== undefined),
    map(([, id]) => ObjectStructureActions.loadNodeChildren({ id })),
  ));

  loadNodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadNodeSuccess),
    mergeMap(() => [
      AppStateActions.ensureNewTask(),
      TaskActions.loadTasks(),
    ]),
  ));

  loadNodeChildrenSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadNodeChildrenSuccess),
    concatLatestFrom(() => this.store.select(fromObjectStructureSelectors.selectChildrenNodeId)),
    map(([, childrenNodeId]) => ObjectStructureActions.loadNode({ id: childrenNodeId })),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      ObjectStructureActions.loadNodeFail,
      ObjectStructureActions.loadNodeChildrenFail,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private objectStructureService: ObjectStructureService,
    private store: Store,
  ) {
  }
}
