<div class="envelop">
  <mat-card class="card">
    <img alt="DIVA_NOTE_logo" class="logo" src="assets/img/white-logo.png">
    <mat-card-title class="card__title" style="margin-top: 4px; margin-bottom: 0;">
      Anmeldung zum Meldesystem
    </mat-card-title>
    <div class="card__divider">
    </div>
    <mat-card-content class="card__content">
      <form (ngSubmit)="submit()" [formGroup]="formGroup" class="form">
        <mat-form-field>
          <div class="card__input">
            <mat-icon>account_circle</mat-icon>
            <input
              [ngModel]="credentials.userName"
              formControlName="userName"
              matInput
              placeholder="Benutzername"
              type="text"
            >
          </div>
        </mat-form-field>

        <mat-form-field>
          <div class="card__input">
            <mat-icon class="justify-content-center">lock</mat-icon>
            <input
              [ngModel]="credentials.password"
              [type]="hide ? 'password' : 'text'"
              formControlName="password"
              matInput
              placeholder="Password"
            >
            <button
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
              mat-icon-button
              matSuffix
              type="button"
            >
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </div>
        </mat-form-field>

        <button
          [disabled]="!formGroup.valid"
          class="button"
          mat-raised-button
          type="submit"
        >
          Anmelden
        </button>

      </form>
    </mat-card-content>
  </mat-card>
</div>
