import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { KnownErrorDescription } from '@core/models';
import { DivaUtility } from '@core/utility';
import { isHttpErrorResponse, isServerException } from '@utility/type-guards';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
  ) {
  }

  default(message: string) {
    this.show(message, {
      duration: 4000,
      panelClass: 'default-notification-overlay',
    });
  }

  info(message: string) {
    this.show(message, {
      duration: 4000,
      panelClass: 'info-notification-overlay',
    });
  }

  success(message: string) {
    this.show(message, {
      duration: 4000,
      panelClass: 'success-notification-overlay',
    });
  }

  warning(message: string) {
    this.show(message, {
      duration: 4000,
      panelClass: 'warning-notification-overlay',
    });
  }

  error(
    options: { id?: string; header?: string; error: any; duration?: number; errorDescriptions?: KnownErrorDescription[] },
  ) {
    const { header, error, duration, errorDescriptions } = options;

    if(DivaUtility.isEmptyObject(error)) {
      // eslint-disable-next-line no-console
      console.warn('Error object is empty');
      return;
    }

    const message = this.decode(error, errorDescriptions);

    this.show(`${header} ${message}`, {
      duration: duration,
      panelClass: 'error-notification-overlay',
      announcementMessage: 'something',
    });
  }

  decode(err: any, errorDescriptions?: KnownErrorDescription[]): string {
    if(typeof err === 'string') {
      return err;
    } else if(err instanceof HttpErrorResponse) {
      // Server or connection error happened
      if(!navigator.onLine) {
        // Handle offline error
        // return this.translocoService.translate('core.services.userNotification.error.offline');
        return '';
      } else {
        if(errorDescriptions) {
          const description = NotificationService.tryGetDescription(err, errorDescriptions);
          if(description) {
            return description;
          }
        }

        // if (error.status === 504) {
        //   return this.translocoService.translate('core.services.userNotification.error.status.504');
        // }

        if(err.status === 400 && err.error.message) {
          return err.error.message;
        }

        if(err.status === 403 && err.error.message) {
          return err.error.message;
        }

        if(err.status === 0) {
          return 'Error status 0';
        }

        if(isServerException(err)) {
          const { status, statusText, error: { message, exceptionType, exceptionMessage } } = err;
          return ` ${status}\n ${statusText}\n Message: ${message}\n Type: ${exceptionType}\n Exception: ${exceptionMessage}`;
        }

        if(isHttpErrorResponse(err)) {
          const { status, statusText, url, error } = err;
          return `status: ${status}\n statusText: ${statusText}\n url: ${url}\n error: ${error}\n`;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return `${err.status}: ${err.statusText}`;
      }
    } else if(err instanceof Error) {
      return err.message;
    } else {
      // Reason: Errors must be visible in the console
      // eslint-disable-next-line no-console
      console.error(err);
      return err && err.message ? `${err.message}` : `${err}`;
      // Handle Client Error (Angular Error, ReferenceError...)
    }
  }

  private static tryGetDescription(error: HttpErrorResponse, knownErrorDescriptions: KnownErrorDescription[]) {
    for(const knownErrorDescription of knownErrorDescriptions) {
      if(knownErrorDescription.status === error.status
        && (!knownErrorDescription.error || knownErrorDescription.error === error.error.error)) {
        return knownErrorDescription.description;
      }
    }

    return null;
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }
}
