import { Action, createReducer, on } from '@ngrx/store';
import { TreeNodeSearch } from '@core/models';
import { TreeNodeSearchApiActions, TreeNodeSearchPageActions } from '../actions';

export const featureKey = 'search';

export interface State {
  treeNodes: TreeNodeSearch[] | null;
  recents: string[];
  suggestions: string[];
  loading: boolean;
  loaded: boolean;
  error: any;
  numberOfSuggestions: number;
  numberOfItemsToTake: number;
  showSearchBar: boolean;
  term: string;
  searchOption: string;
}

const initialState: State = {
  treeNodes: null,
  recents: [],
  suggestions: [],
  loaded: false,
  loading: false,
  error: null,
  term: null,
  numberOfSuggestions: 10,
  numberOfItemsToTake: 20,
  showSearchBar: true,
  searchOption: "",
};

const featureReduce = createReducer(
  initialState,
  on(TreeNodeSearchPageActions.init, () => initialState),
  on(TreeNodeSearchApiActions.loadRecentSearchesSuccess, (state, { recents }): State => ({
    ...state, recents,
  })),
  on(TreeNodeSearchPageActions.changeSearchTerm, (state, { term }): State => ({
    ...state, term,
  })),
  on(TreeNodeSearchPageActions.showSearchBar, (state): State => ({
    ...state, showSearchBar: true,
  })),
  on(TreeNodeSearchApiActions.scanQrCodeSuccess, (state, { nodeId }): State => ({
    ...state, term: `#${nodeId}`, showSearchBar: false,
  })),
  on(TreeNodeSearchPageActions.load, (state, { term, searchOption }): State => ({
    ...state, term, searchOption, loaded: false, loading: true, error: null, showSearchBar: false,
  })),
  on(
    TreeNodeSearchApiActions.search,
    TreeNodeSearchApiActions.searchMore, (state): State => ({
      ...state, loading: true, error: null,
    }),
  ),
  on(TreeNodeSearchApiActions.searchSuccess, (state, { treeNodes }): State => ({
    ...state, treeNodes, loaded: true, loading: false,
  })),
  on(TreeNodeSearchApiActions.searchFail, (state, { error }): State => ({
    ...state, error, loading: false,
  })),
  on(TreeNodeSearchApiActions.searchMoreSuccess, (state, { treeNodes }): State => ({
    ...state, treeNodes: [...state.treeNodes, ...treeNodes], loading: false,
  })),
  on(TreeNodeSearchApiActions.loadSuggestionsSuccess, (state, { suggestions }): State => ({
    ...state, suggestions,
  })),
  on(TreeNodeSearchApiActions.clearRecentSearches, (state): State => ({
    ...state, recents: [],
  })),
  on(TreeNodeSearchPageActions.selectSearchNode, (state): State => ({
    ...state,
    term: null,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return featureReduce(state, action);
}
