import { TSMap } from 'typescript-map';
import { EnumBase } from '@models/enumBase';

export class EnumUtilities {
  static sort = (enumBases: EnumBase[]): EnumBase[] => {
    const tsMap = new TSMap<number, EnumBase>();

    for(const enumBase of enumBases) {
      const { id } = enumBase;
      if(id) {
        tsMap.sortedSet(id, enumBase);
      }
    }

    return tsMap.values();
  };

  static getEnumMapSortedById = (enumBases: EnumBase[]): TSMap<number, EnumBase> => {
    const tsMap = new TSMap<number, EnumBase>();

    for(const enumBase of enumBases) {
      const { id } = enumBase;
      if(id) {
        tsMap.sortedSet(id, enumBase);
      }
    }

    return tsMap;
  };
}
