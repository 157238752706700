import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorIntl {
  constructor(private translate: TranslateService) {
  }

  getPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    this.translate.get('dummy').subscribe(() => {
      paginatorIntl.itemsPerPageLabel = this.translate.instant('PAGER.ITEMS_PER_PAGE_LABEL');
      paginatorIntl.nextPageLabel = this.translate.instant('PAGER.NEXT_PAGE_LABEL');
      paginatorIntl.previousPageLabel = this.translate.instant('PAGER.PREVIOUS_PAGE_LABEL');
      paginatorIntl.firstPageLabel = this.translate.instant('PAGER.FIRST_PAGE_LABEL');
      paginatorIntl.lastPageLabel = this.translate.instant('PAGER.LAST_PAGE_LABEL');
      paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const ofString = this.translate.instant('PAGER.OF');

        if(length === 0 || pageSize === 0) {
          return `0 ${ofString} ${length}`;
        }

        const maxLength: number = Math.max(length, 0);
        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex: number = startIndex < maxLength
          ? Math.min(startIndex + pageSize, maxLength)
          : startIndex + pageSize;

        // return `${startIndex + 1} - ${endIndex} ${ofString} ${maxLength}`;
        return `${startIndex + 1} - ${endIndex}`;
      };

      paginatorIntl.changes.next();
    });

    return paginatorIntl;
  }
}
