import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AppSettingsService } from '@services/app-settings.service';
import { NotificationService } from '@services/notification.service';
import * as fromUser from '@store/selectors/user.selectors';
import { AppSettingsActions } from '../../store/actions/';

@Injectable({ providedIn: 'root' })
export class AppSettingsApiEffects {
  loadSettings$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.loadSettings),
    concatMap(() => of(undefined).pipe(withLatestFrom(this.store.select(fromUser.selectLogged)))),
    switchMap(([, logged]) => logged || environment.isDaimler || environment.isMan
      ? this.appSettingsService.get().pipe(
        map(appSettings => AppSettingsActions.loadSettingsSuccess({ appSettings })),
        catchError(error => of(AppSettingsActions.loadSettingsFail({ error }))),
      )
      : of(AppSettingsActions.loadSettingsSuccess({
        appSettings: {
          minSirLevel: undefined,
          rootNodeId: undefined,
        },
      }))),
  ));

  loadSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.loadSettingsSuccess),
    mergeMap(() => [
      AppSettingsActions.loadEnums(),
    ]),
  ));

  loadEnums$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.loadEnums),
    switchMap(() => forkJoin([
      this.appSettingsService.getDamagePictures(),
      this.appSettingsService.getDivisions(),
      this.appSettingsService.getOrderTypes(),
      this.appSettingsService.getTeamGroups(),
      this.appSettingsService.getTeams(),
      this.appSettingsService.getTrades(),
    ])),
    map((
      [
        damagePictures,
        divisions,
        orderTypes,
        teamGroups,
        teams,
        trades,
      ],
    ) => AppSettingsActions.loadEnumsSuccess({
      damagePictures,
      divisions,
      orderTypes,
      teamGroups,
      teams,
      trades,
    })),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      AppSettingsActions.loadSettingsFail,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private appSettingsService: AppSettingsService,
    private notificationService: NotificationService,
    private store: Store,
  ) {
  }
}
