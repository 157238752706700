import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserService } from '@core/services';
import { environment } from '@environments/environment';
import { NotificationService } from '@services/notification.service';
import * as fromUser from '@store/selectors/user.selectors';
import { AppSettingsActions, UserActions } from '../../store/actions';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  login$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.login),
    switchMap(({ credentials }) => this.userService.login(credentials).pipe(
      map(() => UserActions.loginSuccess({ credentials })),
      catchError(error => of(UserActions.loginFail({ error }))),
    )),
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loginSuccess),
    mergeMap(() => [
      UserActions.loadUser(),
      AppSettingsActions.loadSettings(),
    ]),
  ));

  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUser),
    concatMap(() => of(undefined).pipe(withLatestFrom(this.store.select(fromUser.selectLogged)))),
    switchMap(([, logged]) => logged || !environment.isDaimler
      ? this.userService.loadUser().pipe(
        map(user => UserActions.loadUserSuccess({ user })),
        catchError(error => of(UserActions.loadUserFail({ error }))),
      )
      : this.userService.loadDaimlerUser().pipe(
        map(user => UserActions.loadUserSuccess({ user })),
        catchError(error => of(UserActions.loadUserFail({ error }))),
      )),
  ));

  loadUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(
      UserActions.loadUserSuccess,
    ),
    map(() => AppSettingsActions.loadSettings()),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      UserActions.loginFail,
      UserActions.loadUserFail,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store,
    private userService: UserService,
  ) {
  }
}
