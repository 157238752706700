import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { TSMap } from 'typescript-map';
import { EnumBase, OrderType, Team } from '@core/models';
import { environment } from '@environments/environment';
import { EnumUtilities } from '@utility/enum-utilities';
import { LoggerService } from './logger.service';

const ENUMS_ROOT = `${environment.apiUrl}/enums`;
const DIVISIONS = `${ENUMS_ROOT}/divisions`;
const ORDER_TYPES = `${ENUMS_ROOT}/order-types`;
const DAMAGE_PICTURES = `${ENUMS_ROOT}/damage-pictures`;
const TEAM_GROUPS = `${ENUMS_ROOT}/team-groups`;
const TEAMS = `${ENUMS_ROOT}/teams`;

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  private divisionsDictionary = new TSMap<number, EnumBase>();
  private teamsDictionary = new TSMap<number, EnumBase>();

  constructor(
    private http: HttpClient,
    private log: LoggerService,
  ) {
  }

  getDivisionDescription(id: number) {
    if(this.divisionsDictionary && this.divisionsDictionary.has(id)) {
      const { designation } = this.divisionsDictionary.get(id);
      if(designation) {
        return designation;
      }
    }

    this.log.error({
      class: 'enum.service',
      method: 'getDivisionDescription',
      message: `Division with id:${id} not found`,
    });

    return 'n/a';
  }

  getTeamsDescription(id: number) {
    if(this.teamsDictionary && this.teamsDictionary.has(id)) {
      const { designation } = this.teamsDictionary.get(id);
      if(designation) {
        return designation;
      }
    }

    this.log.error({
      class: 'enum.service',
      method: 'getTeamsDescription',
      message: `Team with id:${id} not found`,
    });

    return 'n/a';
  }

  getTeams(isGrouped: boolean) {
    return this.http
      .get<Team[]>(isGrouped
      ? TEAM_GROUPS
      : TEAMS,
    {
      withCredentials: !environment.isMan && !environment.isDaimler,
    })
      .pipe(
        map(teamGroups => {
          const result: Team[] = [];

          teamGroups.forEach((group: Team) => {
            const element = { ...group };
            element.designation = `${group.code} ${group.name} ${group.surname}`;
            result.push(element);
          });

          return result;
        }),
        tap((x: Team[]) => x.sort((a: Team, b: Team) => a.sortingOrder < b.sortingOrder
          ? -1
          : 1)),
        tap((x: Team[]) => {
          this.teamsDictionary = EnumUtilities.getEnumMapSortedById(x);
        }),
        // tap(x => console.log(x)),
      )
      .toPromise();
  }

  getDivisions() {
    return this.http
      .get<EnumBase[]>(DIVISIONS, { withCredentials: !environment.isMan && !environment.isDaimler })
      .pipe(
        tap((x: EnumBase[]) => x.sort((a: EnumBase, b: EnumBase) => a.sortOrder < b.sortOrder
          ? -1
          : 1)),
        tap((x: EnumBase[]) => {
          this.divisionsDictionary = this.getEnumMapSortedById(x);
        }),
        // tap((x: EnumBase[]) => console.log(x)),
      )
      .toPromise();
  }

  getOrderTypes() {
    return this.http
      .get<OrderType[]>(ORDER_TYPES, { withCredentials: !environment.isMan && !environment.isDaimler })
      .toPromise();
  }

  getDamagePictures() {
    return this.http
      .get<EnumBase[]>(DAMAGE_PICTURES, { withCredentials: !environment.isMan && !environment.isDaimler })
      .toPromise();
  }

  getOrderTypesForTeam(id: number) {
    return this.http.get<OrderType[]>(`${ENUMS_ROOT}/${id}/order-types`).toPromise();
  }

  getDamagePicturesForTeam(id: number, nodeId = 0) {
    return this.http.get<EnumBase[]>(`${ENUMS_ROOT}/${id}/damage-pictures?nodeId=${nodeId}`).toPromise();
  }

  getEnumMapSortedById(enumBases: EnumBase[]) {
    const tsMap = new TSMap<number, EnumBase>();

    for(const enumBase of enumBases) {
      if(enumBase.id) {
        tsMap.sortedSet(enumBase.id, enumBase);
      }
    }

    return tsMap;
  }
}
