import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ScannerDialogData {
  message: string;
}

@Component({
  selector: 'app-scanner-dialog',
  templateUrl: './scanner-dialog.component.html',
  styleUrls: ['./scanner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScannerDialogComponent {
  code = ''; // '292065';
  message = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: ScannerDialogData) {
    this.message = data && data.message;
  }

  onCodeScanned($event: string) {
    this.code = $event;
  }
}
