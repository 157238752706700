import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const selectTaskState = createSelector(
  fromFeature.selectAppState,
  ({ tasks }) => tasks,
);

export const selectLoading = createSelector(
  selectTaskState,
  ({ loading }) => loading,
);

export const selectLoaded = createSelector(
  selectTaskState,
  ({ loaded }) => loaded,
);

export const selectCount = createSelector(
  selectTaskState,
  ({ count }) => count,
);

export const selectTasks = createSelector(
  selectTaskState,
  ({ tasks }) => tasks,
);
