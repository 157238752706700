import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  objectStructureMenuActiveSubject$: Subject<boolean> = new Subject<boolean>();
  tasksMenuActiveSubject$: Subject<boolean> = new Subject<boolean>();
  objectStructureMenuActive$ = this.objectStructureMenuActiveSubject$.asObservable();
  tasksMenuActive$ = this.tasksMenuActiveSubject$.asObservable();

  private loadingSubject = new Subject<boolean>();
  loading$ = this.loadingSubject.asObservable();

  toggleMenu(source: string) {
    switch(source) {
      case 'objects':
        this.turnObjectStructureMenuOn();
        this.turnTasksMenuOff();
        break;
      case 'tasks':
        this.turnObjectStructureMenuOff();
        this.turnTasksMenuOn();
        break;
      default:
        break;
    }
  }

  turnDesktopView() {
    this.turnObjectStructureMenuOn();
    this.turnTasksMenuOn();
  }

  turnMobileView() {
    this.turnObjectStructureMenuOn();
    this.turnTasksMenuOff();
  }

  turnObjectStructureMenuOn() {
    this.objectStructureMenuActiveSubject$.next(true);

    const element = document.getElementById('toggle-object-structure');
    if(element && element.classList) {
      if(!element.classList.contains('object-structure-menu-selected')) {
        element.classList.add('object-structure-menu-selected');
      }
    }
  }

  turnObjectStructureMenuOff() {
    this.objectStructureMenuActiveSubject$.next(false);

    const element = document.getElementById('toggle-object-structure');
    if(element && element.classList) {
      if(element.classList.contains('object-structure-menu-selected')) {
        element.classList.remove('object-structure-menu-selected');
      }
    }
  }

  turnTasksMenuOn() {
    this.tasksMenuActiveSubject$.next(true);

    const element = document.getElementById('toggle-tasks');
    if(element && element.classList) {
      if(!element.classList.contains('tasks-menu-selected')) {
        element.classList.add('tasks-menu-selected');
      }
    }
  }

  turnTasksMenuOff() {
    this.tasksMenuActiveSubject$.next(false);

    const element = document.getElementById('toggle-tasks');
    if(element && element.classList) {
      if(element.classList.contains('tasks-menu-selected')) {
        element.classList.remove('tasks-menu-selected');
      }
    }
  }

  loading(value) {
    this.loadingSubject.next(value);
  }
}
