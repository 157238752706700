declare global {
}

export function none(x: any) {
  return x === null || x === undefined || typeof x === 'string' && x === '' || typeof x === 'number' && x === 0;
}

export function smth(x: any) {
  return x !== null && x !== undefined || typeof x === 'string' && x !== '' || typeof x === 'number' && x !== 0;
}

/*
  interface Object {
    testGet: boolean;
  }
*/

/*
Object.defineProperty(Object.prototype, 'testGet', {
  configurable: true,
  get(this: object) {
    return true;
  },
  enumerable: false,
});
*/

/*
Object.prototype.notEmpty = function(): boolean {
  return (
    Object(this) !== null && Object(this) !== undefined && (typeof Object(this) === 'string' && Object(this) === '')
  );
};

Object.prototype.empty = function(): boolean {
  return (
    Object(this) === null || Object(this) === undefined || (typeof Object(this) === 'string' && Object(this) === '')
  );
};
*/
