<div
  [class]="getSelectedAndSingle() !== null ? 'single' : 'multi'"
  [formGroup]="formGroup"
  [ngClass]="type"
  [ngStyle]="{ height: height }"
  class="select"
>
  <div class="select__icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="select__details">
    <div class="select__details-content">
      <mat-form-field>
        <mat-select
          [disabled]="getSelectedAndSingle() !== null"
          [ngModel]="getSelectedAndSingle()"
          formControlName="{{ controlName }}"
          placeholder="{{ label }}"
        >
          <mat-option *ngFor="let item of items" [value]="item"> {{ item.designation }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
