<!--suppress JSRemoveUnnecessaryParentheses -->
<!--suppress SpellCheckingInspection -->
<div *ngIf="(newTaskComponent$ | async)">
  <mat-card class="mat-elevation-z4">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()" [formGroup]="newTaskForm">
        <div class="new-task">
          <div class="new-task__header-date">{{ today | date: 'dd.MM.yyyy' }}</div>

          <div class="new-task__header-name">{{ activeNode?.name }}</div>

          <div class="new-task__reporter">{{ 'TASK_NEW.REPORTER' | translate }}</div>

          <mat-divider [inset]="true" class="mt-1 mb-3"></mat-divider>

          <div class="task__user">
            <icon-label-info
              height="56px"
              icon="account_circle"
              info="{{ user?.fullName }}"
              label="Name"
              type="user-info"
            ></icon-label-info>
          </div>

          <div *ngIf="user?.telephoneNumber" class="new-task__business-phone">
            <icon-label-info
              height="56px"
              icon="phone"
              info="{{ user?.telephoneNumber }}"
              label="Business Phone"
              type="business-phone"
            ></icon-label-info>
          </div>
          <div *ngIf="isBusinessPhoneRequired" class="new-task__business-phone-input">
            <app-phone-input
              controlName="telephoneNumber"
              formGroupName="group"
              height="64px"
              icon="phone"
              label="{{ 'TASK_NEW.BUSINESS_PHONE' | translate }}"
              type="business-phone"
            ></app-phone-input>
          </div>

          <div *ngIf="user?.mobilePhone" class="new-task__mobile-phone">
            <icon-label-info
              height="56px"
              icon="smartphone"
              info="{{ user?.mobilePhone }}"
              label="Mobile"
              type="mobile-phone"
            ></icon-label-info>
          </div>
          <div *ngIf="isMobilePhoneRequired" class="new-task__mobile-phone-input">
            <app-phone-input
              controlName="mobilePhone"
              formGroupName="group"
              height="56px"
              icon="phone"
              label="{{ 'TASK_NEW.MOBILE_PHONE' | translate }}"
              type="mobile-phone"
            ></app-phone-input>
          </div>

          <div class="new-task__building">
            <app-room-input
              controlName="building"
              formGroupName="group"
              height="64px"
              icon="layers"
              label="{{ 'TASK_NEW.BUILDING' | translate }}"
              room="{{ user?.buildingNumber }}"
              type="room"
            ></app-room-input>
          </div>

          <div class="new-task__room-input">
            <app-room-input
              controlName="room"
              formGroupName="group"
              height="56px"
              icon="layers"
              label="{{ 'TASK_NEW.ROOM' | translate }}"
              room="{{ user?.officeName }}"
              type="room"
            ></app-room-input>
          </div>

          <div class="new-task__failure-details">{{ 'TASK_NEW.PROBLEM_DETAILS' | translate }}</div>

          <mat-divider [inset]="true" class="mt-1 mb-3"></mat-divider>

          <div class="new-task__divisions">
            <group-select-mobile
              [items]="divisionsFiltered"
              controlName="divisions"
              formGroupName="group"
              height="56px"
              icon="beenhere"
              label="{{ 'TASK_NEW.DIVISIONS' | translate }}"
              type="divisions"
            >
            </group-select-mobile>
          </div>

          <div class="new-task__head-group">
            <group-select-mobile
              *ngIf="isDaimler"
              [items]="headGroupsFiltered"
              controlName="headGroups"
              formGroupName="group"
              height="56px"
              icon="people_outline"
              label="{{ 'TASK_NEW.HEAD_GROUP_WITH_OFFER_MOBILE' | translate }}"
              type="head-group"
            >
            </group-select-mobile>
          </div>

          <div *ngIf="isDaimler" class="new-task__horizontal-filler"></div>

          <div class="new-task__sub-group-boundary">
            <div class="new-task__sub-group">
              <group-select-mobile
                *ngIf="isDaimler"
                [items]="subGroupsFiltered"
                controlName="subGroups"
                formGroupName="group"
                height="56px"
                icon="person_outline"
                label="{{ 'TASK_NEW.SUB_GROUP_WITH_OFFER_MOBILE' | translate }}"
                type="sub-group"
              ></group-select-mobile>
            </div>

            <div *ngIf="isDaimler" class="new-task__horizontal-filler"></div>

            <div class="new-task__types-boundary">
              <div class="new-task__order-type">
                <group-select-mobile
                  [items]="orderTypes"
                  controlName="orderTypes"
                  formGroupName="group"
                  height="56px"
                  icon="assessment"
                  label="{{ 'TASK_NEW.ORDER_TYPE_INPUT_MOBILE' | translate }}"
                  type="order-type"
                ></group-select-mobile>
              </div>

              <div class="new-task__horizontal-filler"></div>

              <div class="new-task__damage-picture">
                <group-select-mobile
                  [items]="damagePictures"
                  controlName="damagePictures"
                  formGroupName="group"
                  height="56px"
                  icon="flash_on"
                  label="{{ 'TASK_NEW.DAMAGE_PICTURE_INPUT_MOBILE' | translate }}"
                  type="damage-picture"
                ></group-select-mobile>
              </div>
            </div>
          </div>

          <div class="new-task__description_label">{{ 'TASK_NEW.PROBLEM_DESCRIPTION' | translate }}</div>

          <mat-divider [inset]="true" class="mt-1"></mat-divider>

          <div class="new-task__description">
            <div class="new-task__description-icon">
              <mat-icon>description</mat-icon>
            </div>
            <div class="new-task__description-input">
              <mat-form-field>
                <textarea
                  [formControl]="damagePictureDescriptionControl"
                  matInput
                  placeholder="{{ 'TASK_NEW.DESCRIPTION' | translate }}"
                  rows="3"
                  wrap="soft"
                ></textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="new-task__description-hint">{{ 'TASK_NEW.DESCRIPTION_HINT_MOBILE' | translate }}</div>

          <div *ngIf="mediaFiles" class="new-task__media-files">
            <div class="new-task__media-files-label">{{ 'TASK.ATTACHMENTS' | translate }}</div>
            <div
              *ngFor="let attachment of mediaFiles; trackBy: trackById; let lastOne = last"
              [ngClass]="lastOne ? 'new-task__last_file' : 'new-task__not_last_file'"
            >
              <app-attachment [attachment]="attachment" componentType="active"></app-attachment>
            </div>
          </div>

          <div class="new-task__uploads">
            <app-upload-button></app-upload-button>
          </div>

          <div class="new-task__buttons">
            <button (click)="onCancel()" class="new-task__cancel is-active" mat-button type="reset">
              {{ 'TASK_NEW.CANCEL' | translate | uppercase }}
            </button>
            <button
              [disabled]="!newTaskForm.valid"
              [ngClass]="{
                'is-active': newTaskForm.valid,
                'is-inactive': !newTaskForm.valid
              }"
              class="new-task__save"
              mat-button
              type="submit"
            >
              {{ 'TASK_NEW.SAVE' | translate | uppercase }}
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
