import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TreeNodeSearchPageActions } from '@store/actions';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromSelectors from '@store/selectors/tree-node-search.selectors';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html',
  // todo: fix this
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  searchTerm$ = this.store.select(fromSelectors.selectSearchTerm);
  selectViewModel$ = this.store.select(fromSelectors.selectViewModel);
  appSettingsLoaded$: Observable<boolean> = this.store.select(fromAppSettings.selectLoaded);

  constructor(private store: Store) {
  }

  loadMore() {
    this.store.dispatch(TreeNodeSearchPageActions.loadMore());
  }

  selected(id: number) {
    this.store.dispatch(TreeNodeSearchPageActions.selectSearchNode({ id }));
  }
}
