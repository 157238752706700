import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { KEYS, TREE_ACTIONS, TreeModel, TreeNode } from '@circlon/angular-tree-component';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ObjectNode } from '@models/object-node';
import { NodeBackgroundService } from '@services/node.background.service';
import { ObjectStructureService } from '@services/object-structure.service';
import { ObjectStructureActions } from '@store/index';
import * as fromObjectStructureSettings from '@store/selectors/object-structure.selectors';
import * as fromUser from '@store/selectors/user.selectors';

interface Parent {
  id: number;
  name: string;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-object-structure',
  styleUrls: ['./object-structure.component.scss'],
  templateUrl: './object-structure.component.html',
  // todo: fix this
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectStructureComponent implements OnInit, OnDestroy {
  @ViewChild('tree', { static: false }) tree?: { treeModel: TreeModel };
  @Input() visible = true;

  nodes?: ObjectNode[];

  options = {
    actionMapping: {
      checkboxClick: null,
      contextMenu: null,
      dblClick: null,
      drop: null,
      expanderClick: null,
      mouse: {
        click: (tree: TreeModel, node: TreeNode, $event: any) => {
          if(node.hasChildren) {
            TREE_ACTIONS.ACTIVATE(tree, node, $event);
            if(node.isExpanded) {
              TREE_ACTIONS.DRILL_UP(tree, node, $event);
            } else {
              TREE_ACTIONS.DRILL_DOWN(tree, node, $event);
            }
          } else {
            TREE_ACTIONS.ACTIVATE(tree, node, $event);
          }
        },
      },
    },
    'allowDrag': () => false,
    'allowDrop': () => false,
    'getChildren': (node: TreeNode) => this.getChildren(node),
    keys: {
      [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
      [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
      [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
      [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
      [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_ACTIVE,
      [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_ACTIVE,
    },
    useVirtualScroll: false,
  };

  private nodes$ = this.store.select(fromObjectStructureSettings.selectNodes);
  private currentUser$ = this.store.select(fromUser.selectUser);

  private officeNode: number;

  private subscription = new Subscription();

  constructor(
    private objectStructureService: ObjectStructureService,
    private nodeBackgroundService: NodeBackgroundService,
    private store: Store,
  ) {
  }

  getChildren(node: TreeNode) {
    return this.objectStructureService
      .getChildren(node.data.id, 2)
      .pipe(
        map(nodes => this.objectStructureService.getObjectStructure(nodes, node.id)),
      )
      .toPromise();
  }

  onActivate = (event: any) => {
    if(event.node) {
      const parents: Parent[] = [];
      this.getParents(event.node, this.officeNode, parents);

      this.store.dispatch(ObjectStructureActions.loadNode({ id: event.node.id }));
    }
  };

  ngOnInit() {
    this.subscription.add(this.currentUser$.pipe(
      filter(({ officeNode }) => officeNode !== undefined && officeNode !== null),
      tap(({ officeNode }) => {
        const id = parseInt(officeNode.toString(), 10); // todo: fixme now
        this.officeNode = id;

        this.store.dispatch(ObjectStructureActions.loadNodeChildren({ id }));
      }),
    ).subscribe());

    this.subscription.add(this.nodes$.pipe(
      tap(nodes => {
        if(nodes && nodes.length > 0) {
          this.nodes = JSON.parse(JSON.stringify(nodes)); // todo: fixme now
        }
      }),
    ).subscribe());

    this.store.dispatch(ObjectStructureActions.loadOfficeNode());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setParentBackground(data: any) {
    const element = document.getElementById(data.id);
    if(element) {
      const nodeColor = this.nodeBackgroundService.getNodeColor(data, this.nodes);
      if(nodeColor) {
        element.parentElement.style.backgroundColor = nodeColor;
      }
    }
    return true;
  }

  private getParents(node: TreeNode, startObjectStructureNodeId: number, parents: Parent[]) {
    if(node.parent.data.name === undefined) {
      parents.push({ id: startObjectStructureNodeId, name: 'root' });
      return;
    }

    parents.push({ id: node.parent.id, name: node.parent.data.name });

    this.getParents(node.parent, startObjectStructureNodeId, parents);
  }
}
