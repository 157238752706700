import { Pipe, PipeTransform } from '@angular/core';

/**
 * Sources:
 * https://stackoverflow.com/questions/44961759/highlight-the-search-text-angular-2
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark
 *
 * https://stackoverflow.com/a/45363164
 * The search should be case insensitive but that should not replace the original string case
 */

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, term: string): any {
    if(!term || !value) {
      return value;
    }

    const terms = term
      .replace('  ', ' ')
      .trim()
      .replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
      .split(' ').join('|');
    const regex = new RegExp(`(${terms})`, 'gmi');
    return value.toString().replace(regex, '<mark>$&</mark>');
  }
}
