import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'icon-label-info',
  styleUrls: ['./icon-label-info.component.scss'],
  templateUrl: './icon-label-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelInfoComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() info?: string;
  @Input() height?: string;
  @Input() type?: string;
}
