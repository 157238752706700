import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[TreeNodeSearch/Page] init',
);

export const load = createAction(
  '[TreeNodeSearch/Page] loadSearchResult',
  props<{ term: string, searchOption?: string }>(),
);

export const loadMore = createAction(
  '[TreeNodeSearch/Page] loadMore',
);

export const refresh = createAction(
  '[TreeNodeSearch/Page] refresh',
);

export const changeSearchTerm = createAction(
  '[TreeNodeSearch/Page] changeSearchTerm',
  props<{ term: string }>(),
);

export const showSearchBar = createAction(
  '[TreeNodeSearch/Page] showSearchBar',
);

export const scanQrCode = createAction(
  '[TreeNodeSearch/Page] scanQrCode',
);

export const clearRecentSearches = createAction(
  '[TreeNodeSearch/Page] clearRecentSearches',
);

export const selectSearchNode = createAction(
  '[TreeNodeSearch/Page] selectSearchNode',
  props<{ id: number }>(),
);
