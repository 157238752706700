import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromAppState from '@store/selectors/app-state.selectors';
import * as fromTask from '@store/selectors/task.selectors';
import { DivaUtility } from '@utility/diva-utility';

@Component({
  selector: 'app-tasks',
  styleUrls: ['./tasks.component.scss'],
  templateUrl: './tasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksComponent {
  tasks$ = this.store.select(fromTask.selectTasks);
  handset$ = this.store.select(fromAppState.selectHandset);
  standalone$ = this.store.select(fromAppSettings.selectDeviceStandalone);

  constructor(
    private store: Store,
  ) {
  }

  trackById = (index: number, item) => DivaUtility.trackById(index, item);
}
