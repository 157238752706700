import { createAction, props } from '@ngrx/store';
import { Task } from '@models/task';

export const loadTasks = createAction(
  '[Task] loadTasks',
);

export const loadTasksSuccess = createAction(
  '[Task] loadTasksSuccess',
  props<{ tasks: Task[] }>(),
);

export const loadTasksFailure = createAction(
  '[Task] loadTasksFailure',
  props<{ error: any }>(),
);

export const loadCount = createAction(
  '[Task] loadCount',
);

export const loadCountSuccess = createAction(
  '[Task] loadCountSuccess',
  props<{ count: number }>(),
);

export const loadCountFailure = createAction(
  '[Task] loadCountFailure',
  props<{ error: any }>(),
);
