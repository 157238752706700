import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AppSettings, EnumBase, MediaDataEntry, ObjectNode, OrderType, Task, Team, User } from '@core/models';
import { EnumsService, TasksService } from '@core/services';
import { OrderTypeService } from '@core/services/orderType.service';
import { none } from '@core/shared/extensions';
import { DivaUtility } from '@core/utility';
import { environment } from '@environments/environment';
import { AppStateActions, TaskActions } from '@store/index';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromAppState from '@store/selectors/app-state.selectors';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import * as fromUser from '@store/selectors/user.selectors';
import { AlreadyExistComponent } from '../already-exist/already-exist.component';

@Component({
  selector: 'app-new-task',
  styleUrls: ['./new-task.component.scss'],
  templateUrl: './new-task.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('damagePictureDescription', { static: false }) damagePictureDescription?: ElementRef;
  damagePictureDescriptionControl?: FormControl;
  // damagePictures$ = this.store.select(fromAppSettings.selectDamagePictures);
  damagePictures?: EnumBase[];
  divisions: EnumBase[];
  // divisions$: Observable<EnumBase[]> = this.store.select(fromAppSettings.selectDivisionsFiltered);
  headGroups: Team[];
  isDaimler = environment.isDaimler;
  mediaFiles: MediaDataEntry[] = [];
  newTaskForm?: FormGroup;
  newTaskFormStateSubject$ = this.taskService.newTaskFormStateSubject$;
  // orderTypes$ = this.store.select(fromAppSettings.selectOrderTypes);
  orderTypes?: EnumBase[];
  subGroups: Team[];
  today: number = Date.now();
  user?: User;
  newTaskComponent$: Observable<boolean> = this.store.select(fromAppState.selectNewTaskComponent);

  activeNode: ObjectNode;
  private activeNode$ = this.store.select(fromObjectStructure.selectActiveNode);
  private appSettings?: AppSettings;
  private currentUser$ = this.store.select(fromUser.selectUser);
  private damagePicturesControl?: AbstractControl | null;
  private divisionsControl?: AbstractControl | null;
  private mobilePhoneControl?: AbstractControl | null;
  private businessPhoneControl?: AbstractControl | null;
  private newTaskAttachmentDeletion$ = this.taskService.newTaskAttachmentDeletion$;
  private newTaskUpload$ = this.taskService.newTaskUpload$;
  private orderTypesControl?: AbstractControl | null;
  private buildingControl?: AbstractControl | null;
  private roomControl?: AbstractControl | null;
  private subGroupsControl?: AbstractControl | null;
  private appSettings$ = this.store.select(fromAppSettings.selectAppSettings);

  private subscription = new Subscription();

  constructor(
    private enumsService: EnumsService,
    private formBuilder: FormBuilder,
    private taskService: TasksService,
    public dialog: MatDialog,
    private store: Store,
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
  ) {
  }

  get isBusinessPhoneRequired() {
    if(none(this.user.telephoneNumber)) {
      this.businessPhoneControl.setValidators([Validators.pattern(/(\(?[0-9]{3}\)?-?\s?[0-9]{3}-?[0-9]{4})/)]);
      return true;
    }

    return false;
  }

  get isMobilePhoneRequired() {
    if(none(this.user.mobilePhone)) {
      this.mobilePhoneControl.setValidators([Validators.pattern(/(\(?[0-9]{3}\)?-?\s?[0-9]{3}-?[0-9]{4})/)]);
      return true;
    }

    return false;
  }

  get divisionsFiltered() {
    return this.activeNode.divisionId && this.activeNode.divisionId !== 0
      ? this.divisions.filter(v => v.id === this.activeNode.divisionId)
      : this.divisions;
  }

  get headGroupsFiltered() {
    return this.activeNode.tradeId === 0
      ? this.newTaskForm.controls.group.value.divisions
        ? this.headGroups.filter(v => v.divisionId === this.newTaskForm.controls.group.value.divisions.id)
        : []
      : this.headGroups.filter(v => v.id === this.activeNode.mainGroupId);
  }

  get subGroupsFiltered(): Team[] {
    const headGroup = this.newTaskForm.controls.group.value.headGroups;
    if(environment.isDaimler) {
      if(headGroup) {
        if(this.activeNode.tradeId && this.activeNode.tradeId !== 0) {
          // it means FM-Gewerke node
          const team = this.subGroups.find(subGroup => subGroup.id === this.activeNode.tradeId);
          if(team) {
            return [team];
          } else {
            this.show(`Untergruppe ist nicht verfügbar oder deaktiviert! Id=${this.activeNode.tradeId}`, {
              duration: 5000,
              panelClass: 'error-notification-overlay',
            });
          }
        } else {
          return headGroup && headGroup.id !== 0
            ? this.subGroups.filter(subGroup => subGroup.parentId === headGroup.id)
            : [];
        }
      }
    } else if(headGroup && headGroup.id === 0) {
      return this.subGroups.filter((subGroup: Team) => {
        if(subGroup.code) {
          ['001', '002', '003'].includes(subGroup.code);
        }
      });
    }

    return [];
  }

  trackById = (index: number, item) => DivaUtility.trackById(index, item);

  ngAfterViewInit() {
    if(this.subGroupsControl) {
      this.subscription.add(this.subGroupsControl.valueChanges.pipe(
        filter(v => v !== null),
        filter(v => v.id && v.id !== 0),
        tap(subGroupSelected => {
          if(this.isDaimler) {
            this.enumsService.getOrderTypesForTeam(subGroupSelected.id)
              .then(items => {
                this.orderTypes = items.filter(item=> OrderTypeService.isSelectableOrderType(item));
              });

            this.enumsService.getDamagePicturesForTeam(
              subGroupSelected.id,
              this.activeNode.tradeId !== 0
                ? this.activeNode.id
                : 0,
            ).then(damagePictures => {
              this.damagePictures = damagePictures;
            });

            setTimeout(() => this.orderTypesControl.enable(), 250);
          }
        }),
      ).subscribe());
    }

    if(this.damagePicturesControl) {
      this.subscription.add(this.damagePicturesControl.valueChanges.pipe(
        filter(v => v !== null),
        tap(v => {
          if(this.damagePictureDescription) {
            this.damagePictureDescription.nativeElement.value = v.description;
          }
        }),
      ).subscribe());
    }
  }

  ngOnInit() {
    this.subscription.add(this.appSettings$
      .pipe(
        filter(settings => settings !== undefined && settings.bifmNodeId !== undefined),
        tap(settings => {
          this.appSettings = settings;

          // first get app setting, then ask bifm division
          this.enumsService
            .getDivisions()
            .then((items: EnumBase[]) => {
              this.divisions = items;
            });

          if(!environment.isDaimler) {
            this.enumsService
              .getDamagePictures()
              .then((items: EnumBase[]) => {
                this.damagePictures = items;
              });

            this.enumsService
              .getOrderTypes()
              .then((items: OrderType[]) => {
                this.orderTypes = items.filter(item=> OrderTypeService.isSelectableOrderType(item));
              });
          }
        }),
      ).subscribe());

    this.newTaskForm = this.formBuilder.group({
      group: this.formBuilder.group({
        damagePictureDescription: ['', [Validators.required, Validators.minLength(1)]],
        damagePictures: ['', [Validators.required, Validators.minLength(1)]],
        divisions: ['', [Validators.required, Validators.minLength(1)]],
        headGroups: [
          '',
          this.isDaimler
            ? [Validators.required, Validators.minLength(1)]
            : [],
        ],
        telephoneNumber: [''],
        mobilePhone: [''],
        orderTypes: ['', [Validators.required, Validators.minLength(1)]],
        building: [''],
        room: [''],
        subGroups: [
          '', this.isDaimler
            ? [Validators.required, Validators.minLength(1)]
            : [],
        ],
      }),
    });

    this.subGroupsControl = this.newTaskForm.get('group.subGroups');
    this.orderTypesControl = this.newTaskForm.get('group.orderTypes');
    this.businessPhoneControl = this.newTaskForm.get('group.telephoneNumber');
    this.mobilePhoneControl = this.newTaskForm.get('group.mobilePhone');
    this.buildingControl = this.newTaskForm.get('group.building');
    this.roomControl = this.newTaskForm.get('group.room');
    this.damagePicturesControl = this.newTaskForm.get('group.damagePictures');
    this.damagePictureDescriptionControl = this.newTaskForm.get('group.damagePictureDescription') as FormControl;
    this.divisionsControl = this.newTaskForm.get('group.divisions');

    this.enumsService.getTeams(true).then((items: Team[]) => {
      this.headGroups = items;
    });

    this.enumsService.getTeams(false).then((items: Team[]) => {
      this.subGroups = items;
    });

    this.subscription.add(this.currentUser$.pipe(tap(user => {
      this.user = user;
    })).subscribe());

    this.subscription.add(this.activeNode$
      .pipe(
        tap(({ activeNode }) => {
          if(activeNode) {
            this.activeNode = activeNode;
            this.newTaskFormStateSubject$.next(false);
          }
        }),
      ).subscribe());

    this.subscription.add(this.newTaskAttachmentDeletion$
      .pipe(tap<number>(id => {
        this.mediaFiles = [...this.mediaFiles.filter(value => value.id !== id)];
      })).subscribe());

    this.subscription.add(this.newTaskUpload$
      .pipe(
        tap<Event>(($event: Event) => {
          const element = $event.target as HTMLInputElement;
          if(element && element.files && element.files[0]) {
            const fd = new FormData();
            const file = element.files[0];
            fd.append('upload', file, file.name);

            this.mediaFiles.push({
              date: new Date(file.lastModified),
              designation: file.name,
              fileName: file.name,
              formData: fd,
              size: this.formatBytes(file.size, 1),
              mediaType: file.type.split('/').pop(),
              id: Math.floor(Math.random() * (10000000 - 1000000 + 1) + 1000000),
            });
          }
        }),
      ).subscribe());
  }

  onCancel() {
    if(this.newTaskForm) {
      this.newTaskForm.reset();
    }
    this.mediaFiles = [];
    this.newTaskFormStateSubject$.next(false);

    this.store.dispatch(AppStateActions.newTaskCancel());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async onSubmit() {
    if(this.newTaskForm && !this.newTaskForm.valid) {
      // skip false clicks from second button on form
      return;
    }

    const task = new Task();

    if(!this.newTaskForm || !this.activeNode || !this.user) {
      return;
    }
    const controls = this.newTaskForm.controls.group.value;

    task.creationDate = new Date();
    task.damagePictureDescription = controls.damagePictureDescription;
    task.damagePictureId = controls.damagePictures.id;
    task.orderTypeId = controls.orderTypes.id;
    task.treeNodeId = this.activeNode.id;
    task.mainGroupId = this.isDaimler
      ? controls.headGroups.id
      : undefined;
    task.subGroupId = this.isDaimler
      ? controls.subGroups.id
      : undefined;
    task.detectorBusinessPhone = controls.telephoneNumber
      ? controls.telephoneNumber
      : this.user.telephoneNumber;
    task.detectorMobilePhone = controls.mobilePhone
      ? controls.mobilePhone
      : this.user.mobilePhone;
    task.building = controls.building;
    task.room = controls.room;
    task.detectorName = this.user.fullName;

    const sameSirExist = await this.taskService.checkExistingTasks(task);

    if(!sameSirExist) {
      await this.submitNewTask(task);
    } else if(sameSirExist) {
      if(await this.alreadyExistDialogAsync()) {
        await this.submitNewTask(task);
      } else {
        this.cancelNewTask();
      }
    } else {
      this.cancelNewTask();
    }
  }

  autoHeight() {
    if(!this.damagePictureDescription) {
      return;
    }
    const textArea = this.damagePictureDescription.nativeElement;

    if(textArea.scrollHeight < 130) {
      textArea.style.overflowY = 'hidden';
      textArea.style.height = '0px';
      textArea.style.height = `${textArea.scrollHeight}px`;
    } else if(textArea.style.overflowY !== 'visible') {
      textArea.style.overflowY = 'visible';
    }
  }

  private async alreadyExistDialogAsync() {
    if(!this.activeNode) {
      return;
    }
    const dialogRef = this.dialog.open(AlreadyExistComponent, {
      autoFocus: false,
      data: {
        name: this.activeNode.name,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  private cancelNewTask() {
    if(!this.newTaskForm) {
      return;
    }
    this.newTaskFormStateSubject$.next(false);
    this.newTaskForm.reset();
    this.mediaFiles = [];
  }

  private async submitNewTask(task: Task) {
    if(!this.newTaskForm || !this.mediaFiles || !this.activeNode) {
      return;
    }
    await this.taskService.postNewTask(task, this.mediaFiles.length > 0
      ? this.mediaFiles
      : undefined);

    this.newTaskFormStateSubject$.next(false);
    this.newTaskForm.reset();
    this.mediaFiles = [];

    this.store.dispatch(TaskActions.loadTasks());
    this.store.dispatch(AppStateActions.newTaskSubmit());
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() => this.snackBar.open(message, null, configuration));
  }

  private formatBytes(a: number, b: number) {
    if(0 === a) {
      return '0 Bytes';
    }
    const c = 1024;
    const d = b || 2;
    const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f = Math.floor(Math.log(a) / Math.log(c));
    return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
  }
}
