<div>
  <h2 mat-dialog-title>{{ data.name }}</h2>
  <div class="mat-typography" mat-dialog-content>{{ 'TASK_NEW.ALREADY_EXIST' | translate }}</div>
  <div mat-dialog-actions>
    <div class="already-exist__filler"></div>
    <div>
      <button [mat-dialog-close]="false" mat-button>{{ 'TASK_NEW.REJECT' | translate | uppercase }}</button>
    </div>
    <div>
      <button [mat-dialog-close]="true" mat-button>{{ 'TASK_NEW.SUBMIT' | translate | uppercase }}</button>
    </div>
  </div>
</div>
