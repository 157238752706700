import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { NotificationService } from '@services/notification.service';
import { TaskApiService } from '@services/task-api.service';
import * as fromAppState from '@store/selectors/app-state.selectors';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import { TaskActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class TaskEffects {
  loadTasks$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.loadTasks),
    concatMap(() => of(undefined).pipe(
      withLatestFrom(
        this.store.select(fromObjectStructure.selectActiveNode),
        this.store.select(fromAppState.selectFilter),
        this.store.select(fromAppState.selectSkip),
      ),
    )),
    switchMap(([, { activeNode }, taskFilter, skip]) => this.taskApiService.getTasks(
      activeNode?.id,
      taskFilter,
      skip,
      environment.pageSizeDefault,
    )
      .pipe(
        map((tasks) => TaskActions.loadTasksSuccess({ tasks })),
        catchError(error => of(TaskActions.loadTasksFailure({ error }))),
      )),
  ));

  loadCount$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.loadCount),
    concatMap(() => of(undefined).pipe(
      withLatestFrom(
        this.store.select(fromObjectStructure.selectActiveNode),
        this.store.select(fromAppState.selectFilter),
      ),
    )),
    switchMap(([, { activeNode }, taskFilter]) => this.taskApiService.getTaskCount(activeNode.id, taskFilter).pipe(
      map(count => TaskActions.loadCountSuccess({ count })),
      catchError(error => of(TaskActions.loadCountFailure({ error }))),
    )),
  ));

  loadTasksSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.loadTasksSuccess),
    map(() => TaskActions.loadCount()),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      TaskActions.loadTasksFailure,
      TaskActions.loadCountFailure,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store,
    private taskApiService: TaskApiService,
  ) {
  }
}
