import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  selector: 'app-already-exist',
  styleUrls: ['./already-exist.component.scss'],
  templateUrl: './already-exist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlreadyExistComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string },
  ) {
  }
}
