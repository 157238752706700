<div
  (click)="actionIcon === 'get_app' ? viewAttachment(attachment?.url) : removeAttachment(attachment?.id)"
  class="attachment"
>
  <div class="attachment__icon">
    <mat-icon>{{ contentIcon }}</mat-icon>
  </div>
  <div class="attachment__info">
    <div class="attachment__name">{{ name }}</div>
    <div class="attachment__type">{{ attachment?.mediaType | uppercase }}</div>
<!--    <div class="attachment__size">{{ attachment?.size }}</div>-->
  </div>
  <button *ngIf="actionIcon === 'delete'" class="attachment__action" mat-icon-button>
    <mat-icon>{{ actionIcon }}</mat-icon>
  </button>
  <button *ngIf="actionIcon === 'get_app'" [disabled]="loading" class="attachment__action" mat-icon-button>
    <mat-icon>{{ actionIcon }}</mat-icon>
  </button>
  <div class="attachment__right-side-filler"></div>
</div>
