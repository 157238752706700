import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ObjectStructureApiService } from '@services/object-structure-api.service';
import { ObjectNode, SearchParams, SuggestionsParams, TreeNodeDto } from '../models';

const OBJECT_NODE_API = `${environment.apiUrl}/tree-nodes`;

@Injectable({
  providedIn: 'root',
})
export class ObjectStructureService {
  constructor(
    private http: HttpClient,
    private objectStructureApiService: ObjectStructureApiService,
  ) {
  }

  getRoot(id: number) {
    return this.getChildren(id, 2).pipe(map(nodes => this.getObjectStructure(nodes, id)));
  }

  getObjectStructure(nodes: ObjectNode[], rootId: number) {
    const objectStructure: ObjectNode[] = nodes.filter((node: ObjectNode) => node.parentId === rootId);

    objectStructure.forEach((object: ObjectNode) => {
      if(nodes.findIndex(node => node.parentId === object.id) >= 0) {
        object.children = undefined;
        object.hasChildren = true;
      }
    });

    return objectStructure;
  }

  getChildren(parentId: number, deep = 1) {
    return this.http.get<TreeNodeDto[]>(
      `${OBJECT_NODE_API}/${parentId}/children?deep=${deep}`, { withCredentials: !environment.isMan && !environment.isDaimler },
    ).pipe(
      map(value => this.mapTreeNodeDtosToObjectNodes(value)),
    );
  }

  getTreeNode(id: number) {
    return this.http.get<TreeNodeDto>(
      `${OBJECT_NODE_API}/${id}`, { withCredentials: !environment.isMan && !environment.isDaimler },
    );
  }

  search(term: string, options: SearchParams) {
    return this.objectStructureApiService.search(term, options);
  }

  getSuggestions(term: string, options: SuggestionsParams) {
    return term
      ? this.objectStructureApiService.getSuggestions(term, options)
      : of([]);
  }

  private mapTreeNodeDtosToObjectNodes(dtos: TreeNodeDto[]) {
    const result: ObjectNode[] = [];
    dtos.forEach(dto => result.push(this.mapTreeNodeDtoToObjectNode(dto)));
    return result;
  }

  private mapTreeNodeDtoToObjectNode(dto: TreeNodeDto): ObjectNode {
    return {
      attributes: dto.attributes,
      bottleNeck: dto.attributes
        ? dto.attributes.includes('e')
        : false,
      buildingName: undefined,
      children: [],
      code: dto.code,
      depth: dto.depth,
      hasChildren: false,
      id: dto.id,
      isLeaf: false,
      name: dto.designation,
      parentId: dto.parentId,
      mainGroupId: dto.mainGroupId,
      underBifmMinSirLevel: dto.underBifmMinSirLevel,
      tradeId: dto.tradeId,
    };
  }
}
