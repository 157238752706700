import { currentVersion } from './version';

// ng build --base-href "/diva-note/"

// const urlPrefix = 'http://localhost:80/diva-note/api'; // common
// const urlPrefix = 'https://demo3.cloud.ias-mexis.de/diva-note/api';
// const urlPrefix = 'http://192.168.178.33:80/diva-note/api'; // local at NUC
// const urlPrefix = 'https://demo3.cloud.ias-mexis.de/api';
const urlPrefix = '/api';

export interface Environment {
  debug: boolean;
  isDaimler: boolean;
  isMan: boolean;
  pageSizeDefault: number;
  pageSizeOptionsDefault: number[];
  production: boolean;
  apiUrl: string;
  maxHandsetWidth: string;
  version: string;
}

export const environment: Environment = {
  debug: false,
  production: true,
  isDaimler: false,
  isMan: false,
  pageSizeDefault: 10,
  pageSizeOptionsDefault: [10, 20, 50],
  apiUrl: `${urlPrefix}`,
  maxHandsetWidth: '768px',
  version: currentVersion,
};
