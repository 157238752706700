import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';
import { AppStateService, TasksService } from '@core/services';
import { environment } from '@environments/environment';
import { Device } from '@models/app-settings';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import * as fromUser from '@store/selectors/user.selectors';
import * as fromAppState from '../app/store/selectors/app-state.selectors';
import { AppSettingsActions, AppStateActions } from './store';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  isObjectStructureMenu$ = this.appStateService.objectStructureMenuActive$;
  isTaskMenu$ = this.appStateService.tasksMenuActive$;
  handset$: Observable<boolean> = this.store.select(fromAppState.selectHandset);
  mobile$: Observable<boolean> = this.store.select(fromAppSettings.selectMobile);
  welcome$: Observable<boolean> = this.store.select(fromAppState.selectWelcomeScreen);
  nodeLoaded$: Observable<boolean> = this.store.select(fromObjectStructure.selectNodeLoaded);
  logged$ = this.store.select(fromUser.selectLogged);
  isDaimler$ = of(environment.isDaimler);

  newTaskButton$: Observable<boolean> = this.store.select(fromAppState.selectNewTaskButton);
  newTaskComponent$: Observable<boolean> = this.store.select(fromAppState.selectNewTaskComponent);

  constructor(
    private appStateService: AppStateService,
    private breakpointObserver: BreakpointObserver,
    private deviceService: DeviceDetectorService,
    private store: Store,
    private taskService: TasksService,
    private translate: TranslateService,
  ) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(navigator.language.match(/en|de/)
      ? browserLang
      : 'en');
    this.initBreakpoint();
    const device: Device = this.deviceService.getDeviceInfo();
    if(device) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      device.standalone = navigator.standalone;
      this.store.dispatch(AppSettingsActions.loadDeviceSuccess({ device }));
    } else {
      this.store.dispatch(AppSettingsActions.loadDeviceError({ error: 'Can not recognize device.' }));
    }
    // this.taskService.getAllOrderTypesAndAllDamagePictures();
  }

  initBreakpoint() {
    this.store.dispatch(
      AppStateActions.handset({
        handset: this.breakpointObserver.isMatched([
          '(orientation: portrait)',
          `(max-width: ${environment.maxHandsetWidth})`,
        ]),
      }),
    );
  }

  onNewTask() {
    this.appStateService.toggleMenu('tasks');
    this.taskService.newTaskSmallButtonSubject$.next(false);

    this.store.dispatch(AppStateActions.newTaskButton({ visible: false }));
    this.store.dispatch(AppStateActions.newTaskComponent({ open: true }));
  }

  getClasses(taskMenu: boolean, newTaskComponent: boolean): string {
    const pager = taskMenu
      ? 'with-pager'
      : 'no-pager';
    const header = newTaskComponent
      ? 'no-header'
      : 'with-header';
    return `${pager} ${header}`;
  }

  onClick({ selection }: { selection: 'tree-node' | 'scanner' }) {
    if(selection === 'scanner') {
      const config = {
        autoFocus: true,
        panelClass: ['mat-dialog--fullscreen-mobile'],
      };

      this.store.dispatch(AppStateActions.showScanner({ config }));
    } else {
      this.store.dispatch(AppStateActions.welcome({ welcome: false }));
      this.initBreakpoint();
    }
  }
}
