import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const selectObjectStructureState = createSelector(
  fromFeature.selectAppState,
  ({ objectStructure }) => objectStructure,
);

export const selectActiveNode = createSelector(
  selectObjectStructureState,
  ({ activeNode }) => ({ activeNode }),
);

export const selectSearchFilter = createSelector(
  selectObjectStructureState,
  ({ searchFilter }) => searchFilter,
);

export const selectNodes = createSelector(
  selectObjectStructureState,
  ({ nodes }) => nodes,
);

export const selectNodeLoaded = createSelector(
  selectObjectStructureState,
  ({ loaded }) => loaded,
);

export const selectChildrenNodeId = createSelector(
  selectObjectStructureState,
  ({ childrenNodeId }) => childrenNodeId,
);
