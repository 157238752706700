import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WinAuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(
      req.clone({
        setHeaders: {
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers':
            'X-AspNet-Version, X-Powered-By, Date, Server, Accept, Accept-Encoding, Accept-Language, '
            + 'Cache-Control, Connection, Content-Length, Content-Type, Host, Origin, Pragma, Referer, User-Agent',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, HEAD, OPTIONS',
          'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Max-Age': '600',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          // 'Content-Type': 'application/json',
          // 'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          // 'If-Modified-Since': '0',
          'Pragma': 'no-cache',
        },
        // comment for msal
        // withCredentials: true,
      }),
    );
  }
}

// todo: what is the goal of this file?
