import { createAction, props } from '@ngrx/store';
import { Credentials } from '@models/credentials';
import { User } from '@models/user';

export const login = createAction(
  '[User] login',
  props<{ credentials: Credentials }>(),
);

export const loginSuccess = createAction(
  '[User] loginSuccess',
  props<{ credentials: Credentials }>(),
);

export const loginFail = createAction(
  '[User] loginFail',
  props<{ error: any }>(),
);

export const loadUser = createAction(
  '[User] loadUser',
);

export const loadUserSuccess = createAction(
  '[User] loadUserSuccess',
  props<{ user: User }>(),
);

export const loadUserFail = createAction(
  '[User] loadUserFail',
  props<{ error: any }>(),
);
