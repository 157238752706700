import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { EnumBase } from '@models/enumBase';

@Component({
  selector: 'group-select',
  styleUrls: ['./group-select.component.scss'],
  templateUrl: './group-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSelectComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() height?: string;
  @Input() type?: string;
  @Input() items?: EnumBase[];
  @Input() controlName?: string;

  constructor(public controlContainer: ControlContainer) {
  }

  get formGroup() {
    return this.controlContainer.control as FormGroup;
  }

  getSelectedAndSingle() {
    return this.items && this.items.length === 1
      ? this.items[0]
      : null;
  }
}
