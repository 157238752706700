<!--suppress JSRemoveUnnecessaryParentheses -->
<app-loader></app-loader>

<ng-container
  *ngIf="(isDaimler$ | async) || (logged$ | async); then authenticated; else login">
</ng-container>

<ng-template #login>
  <app-login></app-login>
</ng-template>

<ng-template #authenticated>
  <ng-container *ngIf="(welcome$ | async) && (mobile$ | async); then welcome; else common"></ng-container>

  <ng-template #welcome>
    <div class="d-flex flex-column">
      <!-- Header -->
      <app-header></app-header>

      <!-- Welcome Screen to show at first screen at mobile -->
      <app-welcome-screen (buttonClick)="onClick($event)"></app-welcome-screen>
    </div>
  </ng-template>

  <ng-template #common>
    <ng-container *ngIf="(handset$ | async); then mobile; else desktop"></ng-container>

    <ng-template #mobile>
      <ng-container
        *ngIf="{
        objectStructureMenu: (isObjectStructureMenu$ | async),
        taskMenu: (isTaskMenu$ | async),
        newTaskButton: (newTaskButton$ | async),
        newTaskComponent: (newTaskComponent$ | async)
        } as c"
      >
        <div class="external-envelop d-flex flex-column h-100" [ngClass]="getClasses(c.taskMenu, c.newTaskComponent)">
          <!-- Header -->
          <div *ngIf="!c.newTaskComponent" class="bottom-shadow fixed-top">
            <app-header (buttonClick)="onClick($event)" [scanner]="true"></app-header>
          </div>

          <!-- Middle page content -->
          <div class="d-flex flex-column h-100">
            <!-- Object structure -->
            <div
              *ngIf="c.objectStructureMenu && !c.newTaskComponent"
              class="object-structure h-100"
              style="z-index: 1025;"
            >
              <app-sidenav></app-sidenav>
            </div>

            <!-- Task toolbar -->
            <div *ngIf="c.taskMenu && !c.newTaskComponent" class="sticky-top bottom-shadow">
              <app-task-toolbar></app-task-toolbar>
            </div>

            <!-- New Task Input Form for mobile -->
            <div style="padding: 4px; margin: 4px;">
              <app-new-task-mobile></app-new-task-mobile>
            </div>

            <!-- overflow: auto is very important for pager -->
            <!-- Task Cards -->
            <div *ngIf="c.taskMenu" class="tasks d-flex flex-column" style="overflow: auto;">
              <app-tasks></app-tasks>
            </div>
          </div>

          <!-- Mobile footer -->
          <div *ngIf="!c.newTaskComponent" class="mobile-footer fixed-bottom d-flex flex-column">
            <!-- Task pager -->
            <div *ngIf="c.taskMenu" class="mobile-footer__pager bottom-shadow-small">
              <app-task-pager></app-task-pager>
            </div>

            <!-- Mobile Task Footer menu -->
            <div class="mobile-footer__menu bottom-shadow mb-2">
              <app-footer-menu></app-footer-menu>
            </div>

            <!-- New Task button, if active -->
            <button
              (click)="onNewTask()"
              [disabled]="!c.newTaskButton"
              [ngClass]="c.objectStructureMenu ? 'active' : 'hidden'"
              class="tasks__add-new-mini"
              mat-mini-fab
            >
              +
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #desktop>
      <div class="external-envelop d-flex flex-column h-100">
        <div class="fixed-top bottom-shadow">
          <app-header></app-header>
        </div>

        <div class="d-flex flex-row h-100" style="padding-top: 66px;">
          <div class="w-33 bottom-shadow" style="z-index: 1025;">
            <app-sidenav></app-sidenav>
          </div>

          <div class="w-67 h-100" style="overflow: auto;">
            <div class="task-toolbar bottom-shadow">
              <app-task-toolbar></app-task-toolbar>
            </div>
            <div>
              <app-new-task></app-new-task>
            </div>
            <div *ngIf="(newTaskComponent$ | async) === false" class="tasks">
              <app-tasks *ngIf="(nodeLoaded$ | async)"></app-tasks>
            </div>
            <div *ngIf="(newTaskComponent$ | async) === false" class="pager p-0 m-0">
              <app-task-pager></app-task-pager>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
