import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Credentials } from '@models/credentials';
import { User } from '@models/user';

const USERS_API_LOGIN = `${environment.apiUrl}/users/login`;
const USERS_API_CURRENT = `${environment.apiUrl}/users/current`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  login(credentials: Credentials) {
    return this.httpClient.post(`${USERS_API_LOGIN}`, credentials, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  loadUser(): Observable<User> {
    return this.httpClient
      .get<User>(`${USERS_API_CURRENT}`, { withCredentials: !environment.isMan && !environment.isDaimler })
      .pipe(
        map(user => ({ ...user, fullName: `${user.firstName} ${user.lastName}` })),
      );
  }

  loadDaimlerUser() {
    return this.httpClient
      .get<User>(`${USERS_API_CURRENT}`)
      .pipe(
        map(user => ({ ...user, fullName: `${user.firstName} ${user.lastName}` })),
      );
  }
}
