import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TasksService } from '@services/task.service';

@Component({
  selector: 'app-upload-button',
  styleUrls: ['./upload-button.component.scss'],
  templateUrl: './upload-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadButtonComponent {
  constructor(private tasksService: TasksService) {
  }

  onUpload($event: Event) {
    this.tasksService.newTaskUploadSubject$.next($event);
  }

  onClick(event: MouseEvent) {
    const element = event.target as HTMLInputElement;
    element.value = '';
  }
}
