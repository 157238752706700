import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Task } from '@models/task';
import { TasksService } from '@services/task.service';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';

@Component({
  selector: 'app-task-card-mobile',
  styleUrls: ['./task-card-mobile.component.scss'],
  templateUrl: './task-card-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardMobileComponent {
  @Input() task?: Task;
  @Input() standalone = false;

  newTaskCreated$ = this.taskService.newTaskCreated$;
  damagePictures$ = this.store.select(fromAppSettings.selectDamagePictures);

  isDetailed = false;

  constructor(
    private store: Store,
    private taskService: TasksService,
  ) {
  }

  toggleDetails() {
    this.isDetailed = !this.isDetailed;
  }

  getDamagePicture(damagePictures, id) {
    return this.taskService.getDamagePictureDesignation(damagePictures, id);
  }
}
