import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { ObjectStructureService } from '@services/object-structure.service';
import { TreeNodeSearchApiActions, TreeNodeSearchPageActions } from '../actions';
import * as fromSelectors from '../selectors/tree-node-search.selectors';

@Injectable({ providedIn: 'root' })
export class TreeNodeSearchPageEffects {
  changeSearchTerm$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchPageActions.changeSearchTerm),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select(fromSelectors.selectSuggestionsFilterOptions)),
    )),
    switchMap(([{ term }, options]) => this.objectStructureService.getSuggestions(term, options).pipe(
      map(suggestions => TreeNodeSearchApiActions.loadSuggestionsSuccess({ suggestions })),
      catchError(error => of(TreeNodeSearchApiActions.loadSuggestionsFail({ error }))),
    )),
  ));

  loadSearchResult$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchPageActions.load),
    // tap(async ({term}) => this.objectStructureService.saveRecentSearches(term)),
    map(() => TreeNodeSearchApiActions.search()),
  ));

  refresh$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchPageActions.refresh),
    map(() => TreeNodeSearchApiActions.search()),
  ));

  loadMore$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchPageActions.loadMore),
    map(() => TreeNodeSearchApiActions.searchMore()),
  ));

  showSearchBar$ = createEffect(() => this.actions$.pipe(
    ofType(TreeNodeSearchPageActions.showSearchBar),
    map(() => TreeNodeSearchApiActions.loadRecentSearches()),
  ));

  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      TreeNodeSearchApiActions.loadSuggestionsFail,
    ),
    tap(({error, type}) => this.notificationService.error({
      error,
      duration: 6000,
      header: `${type}\r\n`,
    })),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private objectStructureService: ObjectStructureService,
    private store: Store,
  ) {
  }
}
