export class User {
  buildingNumber?: string;
  telephoneNumber?: string;
  emailAddress?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  mobilePhone?: string;
  room?: string;
  officeNode?: number;
  officeName?: string;
}
