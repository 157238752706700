<div class="footer-menu">
  <button (click)="toggleMenu('objects')" class="footer-menu__object-structure" id="toggle-object-structure" mat-button>
    <mat-icon class="material-icons">share</mat-icon>
    <p class="footer-menu__object-structure-label">{{ 'FOOTER_MENU.OBJECT_STRUCTURE' | translate }}</p>
  </button>
  <button (click)="toggleMenu('tasks')" class="footer-menu__tasks" id="toggle-tasks" mat-button>
    <mat-icon class="material-icons">assignment</mat-icon>
    <p class="footer-menu__tasks-label">{{ taskCount$ | async }}&nbsp;{{ 'FOOTER_MENU.TASKS' | translate }}</p>
  </button>
</div>
