import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppSettings } from '@models/app-settings';
import { EnumBase } from '@models/enumBase';

export const APP_SETTINGS = `${environment.apiUrl}/settings`;
const ENUMS_ROOT = `${environment.apiUrl}/enums`;
const DIVISIONS = `${ENUMS_ROOT}/divisions`;
const ORDER_TYPES = `${ENUMS_ROOT}/order-types`;
const DAMAGE_PICTURES = `${ENUMS_ROOT}/damage-pictures`;
const TEAM_GROUPS = `${ENUMS_ROOT}/team-groups`;
const TEAMS = `${ENUMS_ROOT}/teams`;
const TRADES = `${ENUMS_ROOT}/trades`;

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get<AppSettings>(APP_SETTINGS, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getDamagePictures() {
    return this.http.get<EnumBase[]>(DAMAGE_PICTURES, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getDivisions() {
    return this.http.get<EnumBase[]>(DIVISIONS, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getOrderTypes() {
    return this.http.get<EnumBase[]>(ORDER_TYPES, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getTeamGroups() {
    return this.http.get<EnumBase[]>(TEAM_GROUPS, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getTeams() {
    return this.http.get<EnumBase[]>(TEAMS, { withCredentials: !environment.isMan && !environment.isDaimler });
  }

  getTrades() {
    return this.http.get<EnumBase[]>(TRADES, { withCredentials: !environment.isMan && !environment.isDaimler });
  }
}
