import { createAction, props } from '@ngrx/store';
import { TreeNodeSearch } from '@models/index';

export const loadRecentSearches = createAction(
  '[TreeNodeSearch/API] loadRecentSearches',
);

export const loadRecentSearchesSuccess = createAction(
  '[TreeNodeSearch/API] loadRecentSearchesSuccess',
  props<{ recents: string[] }>(),
);

export const search = createAction(
  '[TreeNodeSearch/API] search',
);

export const searchSuccess = createAction(
  '[TreeNodeSearch/API] searchSuccess',
  props<{ treeNodes: TreeNodeSearch[] }>(),
);

export const searchFail = createAction(
  '[TreeNodeSearch/API] searchFail',
  props<{ error: any }>(),
);

export const searchMore = createAction(
  '[TreeNodeSearch/API] searchMore',
);

export const searchMoreSuccess = createAction(
  '[TreeNodeSearch/API] searchMoreSuccess',
  props<{ treeNodes: TreeNodeSearch[] }>(),
);

export const searchMoreFail = createAction(
  '[TreeNodeSearch/API] searchMoreFail',
  props<{ error: any }>(),
);

export const loadSuggestionsSuccess = createAction(
  '[TreeNodeSearch/API] loadSuggestionsSuccess',
  props<{ suggestions: string[] }>(),
);

export const loadSuggestionsFail = createAction(
  '[TreeNodeSearch/API] loadSuggestionsFail',
  props<{ error: any }>(),
);

export const scanQrCodeSuccess = createAction(
  '[TreeNodeSearch/API] scanQrCodeSuccess',
  props<{ nodeId: number }>(),
);

export const scanQrCodeCancelled = createAction(
  '[TreeNodeSearch/API] scanQrCodeCancelled',
  props<{ reason: string }>(),
);

export const scanQrCodeFail = createAction(
  '[TreeNodeSearch/API] scanQrCodeFail',
  props<{ error: any }>(),
);

export const clearRecentSearches = createAction(
  '[TreeNodeSearch/API] clearRecentSearches',
);

export const clearRecentSearchesCancelled = createAction(
  '[TreeNodeSearch/API] clearRecentSearchesCancelled',
);
