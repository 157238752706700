import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateService } from '@core/services';
import * as fromTasks from '@store/selectors/task.selectors';

@Component({
  selector: 'app-footer-menu',
  styleUrls: ['./footer-menu.component.scss'],
  templateUrl: './footer-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMenuComponent {
  taskCount$ = this.store.select(fromTasks.selectCount);

  constructor(
    private appStateService: AppStateService,
    private store: Store,
  ) {
  }

  toggleMenu(source: string) {
    this.appStateService.toggleMenu(source);
  }
}
