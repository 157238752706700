<div class="task__details">
  <div
    *ngIf="isDaimler" class="task__head-group-name">{{ 'TASK.HEAD_GROUP' | translate | uppercase }}
  </div>
  <div
    *ngIf="isDaimler"
    class="task__sub-group-name">{{ 'TASK.SUB_GROUP' | translate | uppercase }}
  </div>
  <div
    *ngIf="isDaimler"
    class="task__head-group-content word-break">{{ getMainGroupDescription(task?.mainGroupId) }}
  </div>
  <div *ngIf="isDaimler" class="task__sub-group-content word-break">{{ getSubGroupDescription(task?.subGroupId) }}</div>
  <div class="task__damage-description-name">{{ 'TASK_NEW.DESCRIPTION' | translate | uppercase }}</div>
  <div class="task__damage-description-content">{{ task?.damagePictureDescription }}</div>
  <div *ngIf="task?.mediaFiles?.length > 0" class="task__media-files">
    <div class="task__media-files-label">{{ 'TASK.ATTACHMENTS' | translate | uppercase }}</div>
    <div
      *ngFor="let attachment of task?.mediaFiles; trackBy: trackById; let firstOne = first"
      [ngClass]="firstOne ? 'first_file' : 'not_first_file'"
    >
      <div class="task__media_file_info">
        <app-attachment
          [attachment]="attachment"
          [standalone]="standalone"
          [taskId]="task?.id"
          componentType="passive"
        ></app-attachment>
      </div>
    </div>
  </div>
</div>
