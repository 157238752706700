import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUser from '@store/selectors/user.selectors';

@Component({
  selector: 'app-user',
  styleUrls: ['./user.component.scss'],
  templateUrl: './user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent {
  currentUser$ = this.store.select(fromUser.selectUser);

  constructor(
    private store: Store,
  ) {
  }
}
