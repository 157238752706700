import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';

export const selectUserState = createSelector(
  fromFeature.selectAppState,
  ({ user }) => user,
);

export const selectLoading = createSelector(
  selectUserState,
  ({ loading }) => loading,
);

export const selectLoaded = createSelector(
  selectUserState,
  ({ loaded }) => loaded,
);

export const selectUser = createSelector(
  selectUserState,
  ({ user }) => user,
);

export const selectLogged = createSelector(
  selectUserState,
  state => state.logged,
);
