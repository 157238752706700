import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { smth } from '@core/shared/extensions';
import { ObjectStructureActions } from '@store/index';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromAppState from '@store/selectors/app-state.selectors';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy {
  @Input() scanner = false;
  @Output() readonly buttonClick = new EventEmitter<{ selection: 'tree-node' | 'scanner' }>();
  handset$: Observable<boolean> = this.store.select(fromAppState.selectHandset);
  rootNodeId: number;

  private appSettings$ = this.store.select(fromAppSettings.selectAppSettings);
  private subscription = new Subscription();

  constructor(private store: Store) {
    this.subscription.add(this.appSettings$
      .pipe(
        filter(appSettings => appSettings !== undefined && smth(appSettings) && smth(appSettings.rootNodeId)),
        tap(appSettings => {
          if(appSettings !== undefined) {
            this.rootNodeId = appSettings.rootNodeId;
          }
        }),
      ).subscribe());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClick() {
    this.store.dispatch(ObjectStructureActions.loadNodeChildren({ id: this.rootNodeId }));
  }
}
