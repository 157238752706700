import { HttpErrorResponse } from '@angular/common/http';
import { ServerException } from '@core/models';

export function isServerException(obj: any): obj is ServerException {
  return obj.error && obj.error.message !== undefined && obj.error.exceptionType !== undefined;
}

export function isHttpErrorResponse(obj: any): obj is HttpErrorResponse {
  return obj.status !== undefined && obj.statusText !== undefined && obj.url !== undefined && obj.error !== undefined;
}

export const isArray = (value: unknown): value is [] => Array.isArray(value);

export const isNotEmptyArray = (value: unknown): value is [] => Array.isArray(value) && value.length > 0;
