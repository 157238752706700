import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { environment } from '@environments/environment';
import { effects, featureKey, reducers, UserActions } from '../store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class AppStoreModule {
  constructor(
    private store: Store,
  ) {
    if(environment.isDaimler) {
      // todo: check this in Daimler's build
      this.store.dispatch(UserActions.loadUser());
    }
    // this.store.dispatch(AppSettingsActions.loadSettings());
  }
}
