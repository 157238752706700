import { createReducer, on } from '@ngrx/store';
import { Task } from '@models/task';
import { TaskActions } from '../actions';

export const featureKey = 'tasks';

export interface State {
  count: number;
  tasks: Task[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  count: 0,
  tasks: undefined,
  loaded: false,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(TaskActions.loadCount, (state): State => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(TaskActions.loadCountSuccess, (state, { count }): State => ({
    ...state,
    count,
    loading: false,
    loaded: true,
  })),
  on(TaskActions.loadCountFailure, (state): State => ({
    ...state,
    count: 0,
    loading: false,
    loaded: true,
  })),
  on(TaskActions.loadTasks, (state): State => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(TaskActions.loadTasksSuccess, (state, { tasks }): State => ({
    ...state,
    tasks,
    loading: false,
    loaded: true,
  })),
  on(TaskActions.loadTasksFailure, (state): State => ({
    ...state,
    tasks: undefined,
    loading: false,
    loaded: true,
  })),
);
