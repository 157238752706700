<!--suppress JSRemoveUnnecessaryParentheses -->
<ng-container
  *ngIf="{
  handset: (handset$ | async),
  standalone: (standalone$ | async)
  } as c"
>
  <div class="tasks">
    <div *ngFor="let task of (tasks$ | async); trackBy: trackById" class="task-card">
      <div *ngIf="!c.handset">
        <app-task-card [standalone]="c.standalone" [task]="task"></app-task-card>
      </div>
      <div *ngIf="c.handset">
        <app-task-card-mobile [standalone]="c.standalone" [task]="task"></app-task-card-mobile>
      </div>
    </div>
  </div>
</ng-container>
