import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DivaUtility } from '@core/utility';
import { environment } from '@environments/environment';
import { Task } from '@models/task';
import { EnumsService } from '@services/enums.service';

@Component({
  selector: 'app-task-card-details',
  styleUrls: ['./task-card-details.component.scss'],
  templateUrl: './task-card-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardDetailsComponent {
  @Input() task?: Task;
  @Input() standalone = false;

  isDaimler = environment.isDaimler;

  trackById = (index: number, item) => DivaUtility.trackById(index, item);

  constructor(private enumService: EnumsService) {
  }

  getMainGroupDescription(id: number) {
    return this.enumService.getDivisionDescription(id);
  }

  getSubGroupDescription(id: number) {
    return this.enumService.getTeamsDescription(id);
  }
}
