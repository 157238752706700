import { ActionReducer, UPDATE } from '@ngrx/store';
import { RECOMPUTE } from '@ngrx/store-devtools';
import { storeLogger } from '../../core/ngrx-store-logger';
import { State } from './index';

// default, no options
const ngrxStoreLogger = (reducer: ActionReducer<State>): any => storeLogger({
  collapsed: true,
  duration: false,
  timestamp: false,
  filter: {
    blacklist: [
      RECOMPUTE,
      UPDATE,
    ],
  },
})(reducer);

const simpleConsoleLogger = (reducer: ActionReducer<State>): any => (state, action) => {
  // eslint-disable-next-line no-console
  console.log(JSON.stringify(action));
  return reducer(state, action);
};

export const getLogger = () => ngrxStoreLogger;
