import { createAction, props } from '@ngrx/store';
import { ObjectNode } from '@core/models';

export const loadNode = createAction(
  '[Object Structure] loadNode',
  props<{ id: number }>(),
);

export const loadNodeSuccess = createAction(
  '[Object Structure] loadNodeSuccess',
  props<{ node: ObjectNode }>(),
);

export const loadNodeFail = createAction(
  '[Object Structure] loadNodeFail',
  props<{ error: any }>(),
);

export const loadNodeChildren = createAction(
  '[Object Structure] loadNodeChildren',
  props<{ id: number }>(),
);

export const loadNodeChildrenSuccess = createAction(
  '[Object Structure] loadNodeChildrenSuccess',
  props<{ nodes: ObjectNode[] }>(),
);

export const loadNodeChildrenFail = createAction(
  '[Object Structure] loadNodeChildrenFail',
  props<{ error: any }>(),
);

export const filterObjectStructure = createAction(
  '[Object Structure] filterObjectStructure',
  props<{ searchFilter: string }>(),
);

export const loadOfficeNode = createAction(
  '[Object Structure] loadOfficeNode',
);
