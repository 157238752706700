<div class="upload-button">
  <div class="upload-button__envelop">
    <div class="upload-button__attachment-icon"><mat-icon>attach_file</mat-icon></div>
    <div class="upload-button__name">{{ 'TASK.UPLOAD_ATTACHMENTS' | translate }}</div>
    <div class="upload-button__upload-icon"><mat-icon>publish</mat-icon></div>
    <div class="upload-button__right-side-filler"></div>
    <input
      (change)="onUpload($event)"
      (click)='onClick($event)'
      accept="audio/*, image/*, video/*, .pdf, .txt, .doc, .docx, .xls, .xlsx"
      title=""
      type="file"
    />
  </div>
  <div class="upload-button__file-types">IMAGE / TEXT / VIDEO / AUDIO</div>
</div>
