import { Action, combineReducers, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '@environments/environment';
import { getLogger } from '@store/reducers/logger';
import * as fromRoot from '../../reducers';
import * as fromAppSettings from './app-settings.reducer';
import * as fromAppState from './app-state.reducer';
import * as fromObjectStructure from './object-structure.reducer';
import * as fromTask from './task.reducer';
import * as fromTreeNodeSearch from './tree-node-search.reducer';
import * as fromUser from './user.reducer';

export const featureKey = 'appComponent';

export interface State extends fromRoot.State {
  [fromAppSettings.featureKey]: fromAppSettings.State;
  [fromAppState.featureKey]: fromAppState.State;
  [fromObjectStructure.featureKey]: fromObjectStructure.State;
  [fromTask.featureKey]: fromTask.State;
  [fromTreeNodeSearch.featureKey]: fromTreeNodeSearch.State;
  [fromUser.featureKey]: fromUser.State;
}

export const reducers = (state: State, action: Action) => combineReducers({
  [fromAppSettings.featureKey]: fromAppSettings.reducer,
  [fromAppState.featureKey]: fromAppState.reducer,
  [fromObjectStructure.featureKey]: fromObjectStructure.reducer,
  [fromTask.featureKey]: fromTask.reducer,
  [fromTreeNodeSearch.featureKey]: fromTreeNodeSearch.reducer,
  [fromUser.featureKey]: fromUser.reducer,
})(state, action);

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [getLogger()]
  : [];

export const selectAppState = createFeatureSelector<State>(featureKey);

export const selectTreeNodeSearchState = createSelector(
  selectAppState,
  state => state[fromTreeNodeSearch.featureKey],
);
