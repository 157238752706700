import { createReducer, on } from '@ngrx/store';
import { AppStateActions } from '../actions';

export const featureKey = 'appState';

export interface State {
  handset: boolean;
  filter: number;
  skip: number;
  newTask: boolean;
  newTaskButton: boolean;
  newTaskComponent: boolean;
  welcome: boolean;
  scannedCode: string;
  loading: boolean;
}

export const initialState: State = {
  handset: false,
  filter: 0,
  skip: 0,
  newTask: false,
  newTaskButton: false,
  newTaskComponent: false,
  welcome: true,
  scannedCode: undefined,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(AppStateActions.handset, (state, { handset }): State => ({
    ...state,
    handset,
  })),
  on(AppStateActions.filterTasks, (state, { filter }): State => ({
    ...state,
    filter,
  })),
  on(AppStateActions.skipTasks, (state, { skip }): State => ({
    ...state,
    skip,
  })),
  on(AppStateActions.newTask, (state, { enable }): State => ({
    ...state,
    newTask: enable,
  })),
  on(AppStateActions.newTaskButton, (state, { visible }): State => ({
    ...state,
    newTaskButton: visible,
  })),
  on(AppStateActions.newTaskComponent, (state, { open }): State => ({
    ...state,
    newTaskComponent: open,
  })),
  on(AppStateActions.welcome, (state, { welcome }): State => ({
    ...state,
    welcome,
  })),
  on(AppStateActions.scanSuccess, AppStateActions.scanCanceled, (state, { code }): State => ({
    ...state,
    scannedCode: code,
  })),
  on(AppStateActions.loading, (state): State => ({
    ...state,
    loading: true,
  })),
  on(AppStateActions.loaded, (state): State => ({
    ...state,
    loading: false,
  })),
);
