import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaDataEntry } from '@models/media-data-entry';
import { TasksService } from '@services/task.service';

@Component({
  selector: 'app-attachment',
  styleUrls: ['./attachment.component.scss'],
  templateUrl: './attachment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentComponent {
  @Input() attachment?: MediaDataEntry;
  @Input() componentType?: string;
  @Input() taskId?: number;
  @Input() standalone = false;
  loading?: boolean;

  constructor(private tasksService: TasksService) {
  }

  get actionIcon() {
    switch(this.componentType) {
      case 'active':
        return 'delete';
      case 'passive':
        return 'get_app';
      default:
        return undefined;
    }
  }

  get contentIcon() {
    switch(this.attachment.mediaType?.split('/').pop()) {
      case 'bmp':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'tiff':
      case 'webp':
        return 'photo';
      case 'azw':
      case 'csv':
      case 'doc':
      case 'docx':
      case 'epub':
      case 'odp':
      case 'ods':
      case 'odt':
      case 'pdf':
      case 'rtf':
      case 'txt':
        return 'library_books';
      case 'aac':
      case 'aif':
      case 'aifc':
      case 'aiff':
      case 'au':
      case 'mp3':
      case 'oga':
      case 'ogx':
      case 'ra':
      case 'ram':
      case 'snd':
      case 'wav':
      case 'weba':
        return 'mic';
      case '3gp':
      case 'avi':
      case 'flv':
      case 'mov':
      case 'mp4':
      case 'mpeg':
      case 'ogv':
      case 'quicktime':
      case 'ts':
      case 'webm':
      case 'wmv':
        return 'videocam';
      case '7z':
      case 'rar':
      case 'tar':
      case 'zip':
        return 'folder';
      default:
        return 'attach_file';
    }
  }

  get name() {
    return this.attachment.designation?.substring(0, this.attachment.designation?.lastIndexOf('.'))
      .replace('DIVA NOTE: ', '')
      .replace('DIVA GO: ', '');
  }

  removeAttachment(id: number) {
    if(this.componentType === 'active') {
      this.tasksService.newTaskAttachmentDeletionSubject$.next(id);
    }
  }

  viewAttachment(url: string) {
    if(url && !this.standalone) {
      window.open(url, '_blank');
    }
  }
}
