import { createReducer, on } from '@ngrx/store';
import { ObjectNode } from '@core/models';
import { ObjectStructureActions } from '../actions';

export const featureKey = 'objectStructure';

export interface State {
  activeNode: ObjectNode;
  childrenNodeId: number;
  nodes: ObjectNode[];
  searchFilter: string;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  activeNode: undefined,
  childrenNodeId: undefined,
  nodes: Array<ObjectNode>(),
  searchFilter: '',
  loading: false,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(ObjectStructureActions.loadNodeSuccess, (state, { node }): State => ({
    ...state,
    activeNode: node,
    loading: false,
    loaded: true,
  })),
  on(ObjectStructureActions.filterObjectStructure, (state, { searchFilter }): State => ({
    ...state,
    searchFilter,
  })),
  on(ObjectStructureActions.loadNodeChildren, (state, { id }): State => ({
    ...state,
    childrenNodeId: id,
  })),
  on(ObjectStructureActions.loadNodeChildrenSuccess, (state, { nodes }): State => ({
    ...state,
    nodes,
    activeNode: undefined,
    searchFilter: '',
    loading: false,
    loaded: false,
  })),
);
