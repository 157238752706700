export class DivaUtility {
  static trackById<T extends { id: number | string }>(_: number, item: T): number | string | null {
    return item?.id;
  }

  static isEmptyObject(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return !(obj instanceof Error);
  }
}
