<div class="object-structure__tree" *ngIf="visible">
  <tree-root
    #tree
    (activate)="onActivate($event)"
    [nodes]="nodes"
    [options]="options"
  >>
    <ng-template #treeNodeFullTemplate let-index="index" let-node let-templates="templates">
      <div
        [class.tree-node-active]="node.isActive"
        [class.tree-node-collapsed]="node.isCollapsed && node.hasChildren"
        [class.tree-node-expanded]="node.isExpanded && node.hasChildren"
        [class.tree-node-focused]="node.isFocused"
        [class.tree-node-leaf]="node.isLeaf"
        [class.tree-node]="true"
        [ngClass]="node.getClass()"
      >
        <tree-node-wrapper [index]="index" [node]="node" [templates]="templates"></tree-node-wrapper>
        <tree-node-children [node]="node" [templates]="templates"></tree-node-children>
      </div>
    </ng-template>
    <ng-template #treeNodeTemplate let-index="index" let-node>
      <div [id]="node.data.id" *ngIf="setParentBackground(node.data)"></div>
      <div *ngIf="node.hasChildren">
        <div>
          <mat-icon *ngIf="!node.isExpanded" class="tree-node__toggle">add_circle_outline</mat-icon>
          <mat-icon *ngIf="node.isExpanded" class="tree-node__toggle"> remove_circle</mat-icon>
        </div>
      </div>
      <div *ngIf="!node.hasChildren">
        <mat-icon class="tree-node__toggle">label_outline</mat-icon>
      </div>
      <div class="tree-node__level">[{{ node.data.depth }}]</div>
      <div class="tree-node__name">{{ node.data.name }}</div>
      <div>
        <mat-icon *ngIf="!node.isActive" class="tree-node__suffix"> radio_button_unchecked</mat-icon>
      </div>
      <div>
        <mat-icon *ngIf="node.isActive" class="tree-node__suffix"> check_circle</mat-icon>
      </div>
    </ng-template>
    <ng-template #loadingTemplate>
    </ng-template>
  </tree-root>
</div>
