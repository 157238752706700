import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Task } from '@models/task';

const TASK_API = `${environment.apiUrl}/maintenance`;

@Injectable({
  providedIn: 'root',
})
export class TaskApiService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getTaskCount(id: number, filter: number) {
    return this.httpClient.get<number>(
      `${TASK_API}/${id}/count?taskFilter=${filter}`, { withCredentials: !environment.isMan && !environment.isDaimler },
    );
  }

  getTasks(id: number, filter: number, skip: number, take: number) {
    return this.httpClient.get<Task[]>(
      `${TASK_API}/detailed/?treeNodeId=${id}&taskFilter=${filter}&skip=${skip}&take=${take}`, {
        withCredentials: !environment.isMan && !environment.isDaimler,
      },
    );
  }
}
