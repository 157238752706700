/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Logger } from '@models/logger';

const isDebugMode = !environment.production;

@Injectable({ providedIn: 'root' })
export class LoggerService implements Logger {
  error(content: any) {
    if(isDebugMode) {
      console.error(content);
    }
  }

  info(content: any) {
    if(isDebugMode) {
      console.info(content);
    }
  }

  warn(content: any) {
    if(isDebugMode) {
      console.warn(content);
    }
  }
}
