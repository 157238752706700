import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Credentials } from '@models/credentials';
import { UserActions } from '@store/actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  credentials: Credentials = { userName: undefined, password: undefined };
  hide = true;

  formGroup = new FormGroup({
    userName: new FormControl('test', [Validators.minLength(1), Validators.required]),
    password: new FormControl('test', [Validators.minLength(1), Validators.required]),
  });

  constructor(
    private store: Store,
  ) {
  }

  submit() {
    if(this.formGroup.valid) {
      this.store.dispatch(UserActions.login({
        credentials: {
          userName: this.formGroup.value.userName,
          password: this.formGroup.value.password,
        },
      }));
    }
  }
}
