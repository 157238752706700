import { OrderType } from "@core/models";

export class OrderTypeService {
  static readonly attributeWarranty = "warranty,";
  static readonly attributeOptionalStaff = "optionalStaff,";
  static readonly attributeMtbf = "mtbf,";
  static readonly attributeSystem = "system,";
  static readonly attributeHidden = "hidden,";
  static readonly attributePlanned = "planned,";

  static isSelectableOrderType(orderType: OrderType) {
    const {attributes} = orderType;
    const hasAttribute = (attrName: string) => attributes.indexOf(attrName) !== -1;
    return !hasAttribute(this.attributeHidden)
      && !hasAttribute(this.attributePlanned)
      && !hasAttribute(this.attributeSystem);
  }
}
