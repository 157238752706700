<div [formGroup]="formGroup" [ngClass]="type" [ngStyle]="{ height: height }" class="room_input">
  <div class="room_input__icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="room_input__room">
    <mat-form-field class="room_input__content">
      <input [formControlName]="controlName" [ngModel]="room" [placeholder]="label" matInput type="text"/>
    </mat-form-field>
  </div>
</div>
