import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { concatMap, delay, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AppStateService } from '@services/app-state.service';
import { AppStateActions, ObjectStructureActions } from '@store/actions';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromAppState from '@store/selectors/app-state.selectors';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import { ScannerDialogComponent, ScannerDialogData } from '../../ui/scanner-dialog/scanner-dialog.component';

const windowStopScanningVariable = 'stopScanning';

@Injectable({ providedIn: 'root' })
export class AppStateEffects {
  breakpointObserver$ = createEffect(() => this.breakpointObserver.observe([`(max-width: ${environment.maxHandsetWidth})`]).pipe(
    map(state => {
      // const orientation: boolean = state.breakpoints['(orientation: portrait)'];
      const width = state.breakpoints[`(max-width: ${environment.maxHandsetWidth})`];
      return AppStateActions.handset({ handset: /* orientation &&*/ width });
    }),
  ));

  handset$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppStateActions.handset),
      filter(({ handset }) => handset /* === true*/), // !!
      tap(() => {
        document.body.classList.add('mobile');
        document.body.classList.remove('desktop');
      }),
      delay(0), // fixmenow
      tap(() => this.appStateService.turnMobileView()),
    ),
    { dispatch: false },
  );

  desktop$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppStateActions.handset),
      filter(({ handset }) => !handset),
      tap(() => {
        document.body.classList.add('desktop');
        document.body.classList.remove('mobile');
      }),
      delay(0), // fixmenow
      tap(() => this.appStateService.turnDesktopView()),
    ),
    { dispatch: false },
  );

  ensureNewTask$ = createEffect(() => this.actions$.pipe(
    ofType(AppStateActions.ensureNewTask),
    concatMap(() => of(undefined).pipe(
      withLatestFrom(
        this.store.select(fromObjectStructure.selectActiveNode),
        this.store.select(fromAppSettings.selectDaimler),
        this.store.select(fromAppSettings.selectAppSettings),
      ),
    )),
    map(([, { activeNode }, , appSettings]) => {
      if(!activeNode) {
        return AppStateActions.newTask({ enable: false });
      }
      if(activeNode.attributes) {
        if(activeNode.depth >= appSettings.minSirLevel) {
          if(appSettings.mpuNodes && appSettings.mpuNodes.length === 0) {
            return AppStateActions.newTask({ enable: true });
          }
          if(appSettings.mpuNodes && !Array.from(activeNode.attributes).some(v => appSettings.mpuNodes.includes(v))) {
            return AppStateActions.newTask({ enable: true });
          }
          return AppStateActions.newTask({ enable: false });
        }
        return AppStateActions.newTask({ enable: false });
      }
      if(activeNode.depth >= appSettings?.minSirLevel) {
        return AppStateActions.newTask({ enable: true });
      }
      return AppStateActions.newTask({ enable: false });
    }),
  ));

  showScanner$ = createEffect(() => this.actions$.pipe(
    ofType(AppStateActions.showScanner),
    exhaustMap(({ config }) => this.dialog
      .open<ScannerDialogComponent, ScannerDialogData>(ScannerDialogComponent, config)
      .afterClosed()
      .pipe(
        map(code => code
          ? AppStateActions.scanSuccess({ code })
          : AppStateActions.scanCanceled({ code: undefined })),
      )),
  ));

  scanSuccessAndCancel$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppStateActions.scanSuccess, AppStateActions.scanCanceled),
      map(() => {
        window[windowStopScanningVariable] = true;
      }),
    ),
    { dispatch: false },
  );

  scanSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AppStateActions.scanSuccess),
    mergeMap(({ code }) => [
      ObjectStructureActions.loadNodeChildren({ id: +code }),
      AppStateActions.welcome({ welcome: false }),
      AppStateActions.newTaskButton({ visible: false }),
      AppStateActions.newTaskComponent({ open: true }),
    ]),
  ));

  loadNode$ = createEffect(() => this.actions$.pipe(
    ofType(ObjectStructureActions.loadNodeSuccess),
    concatMap(() => of(undefined).pipe(withLatestFrom(this.store.select(fromAppState.selectScannedCode)))),
    mergeMap(([, { scannedCode }]) => scannedCode
      ? [AppStateActions.newTaskButton({ visible: false }), AppStateActions.newTaskComponent({ open: true })]
      : [AppStateActions.newTaskButton({ visible: true }), AppStateActions.newTaskComponent({ open: false })]),
  ));

  newTaskSubmitAndCancel$ = createEffect(() => this.actions$.pipe(
    ofType(AppStateActions.newTaskSubmit, AppStateActions.newTaskCancel),
    tap(() => this.appStateService.turnTasksMenuOn()),
    mergeMap(() => [
      AppStateActions.newTaskButton({ visible: true }),
      AppStateActions.newTaskComponent({ open: false }),
    ]),
  ));

  loading$ = createEffect(() => from(this.appStateService.loading$).pipe(
    switchMap((value) => value
      ? of(AppStateActions.loading())
      : of(AppStateActions.loaded())),
  ));

  constructor(
    private actions$: Actions,
    private appStateService: AppStateService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private store: Store,
  ) {
  }
}
