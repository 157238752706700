import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNodeSearch } from '@core/models';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListComponent {
  @Input() nodes: TreeNodeSearch[];
  @Input() canLoadMore = false;
  @Input() searchText: string;

  @Output() readonly loadMore = new EventEmitter();
  @Output() readonly selected = new EventEmitter<number>();
}
