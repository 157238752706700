import { createSelector } from '@ngrx/store';
import { SearchParams, SuggestionsParams } from '@models/index';
import { selectTreeNodeSearchState } from '../reducers';

const selectRecent = createSelector(
  selectTreeNodeSearchState,
  ({ recents, term }) => recents?.length > 0 && term
    ? recents.filter(recent => recent.includes(term) && recent !== term)
    : recents,
);

const selectSuggestions = createSelector(
  selectTreeNodeSearchState,
  ({ suggestions, recents, term }) => suggestions?.length > 0
    ? suggestions
      .filter(suggestion => suggestion !== term)
      .filter(suggestion => !recents || !recents.some(recent => recent === suggestion))
    : suggestions,
);

export const selectViewModel = createSelector(
  selectTreeNodeSearchState,
  selectRecent,
  selectSuggestions,
  (state, recents, suggestions) => ({
    treeNodes: state.treeNodes,
    loaded: state.loaded,
    loading: state.loading,
    error: state.error,
    term: state.term,
    showSearchBar: state.showSearchBar,
    canLoadMore: state.treeNodes?.length && state.treeNodes.length % state.numberOfItemsToTake === 0,
    recents,
    suggestions,
  }),
);

export const selectSuggestionsFilterOptions = createSelector(
  selectTreeNodeSearchState,
  ({ numberOfSuggestions }): SuggestionsParams => ({
    take: numberOfSuggestions,
  }),
);

export const selectSearchTerm = createSelector(
  selectTreeNodeSearchState,
  state => state.term,
);

export const selectSearchFilter = createSelector(
  selectTreeNodeSearchState,
  (state):SearchParams => ({term:state.term, searchOption:state.searchOption, take: state.numberOfItemsToTake}),
);

export const selectFilterOptions = createSelector(
  selectTreeNodeSearchState,
  ({ numberOfItemsToTake,searchOption }): SearchParams => ({
    take: numberOfItemsToTake,
    searchOption: searchOption
  }),
);

export const selectMoreFilterOptions = createSelector(
  selectTreeNodeSearchState,
  selectFilterOptions,
  ({ treeNodes }, filterOptions): SearchParams => ({
    ...filterOptions,
    skip: treeNodes.length,
  }),
);
