import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TSMap } from 'typescript-map';
import { environment } from '@environments/environment';
import { EnumsService } from '@services/enums.service';
import { LoggerService } from '@services/logger.service';
import { EnumBase, MediaDataEntry, Task } from '../models';
import { smth } from '../shared/extensions';

const TASK_API = `${environment.apiUrl}/maintenance`;

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  newTaskFormStateSubject$: Subject<boolean> = new Subject<boolean>();
  newTaskBigButtonSubject$: Subject<boolean> = new Subject<boolean>();
  newTaskSmallButtonSubject$: Subject<boolean> = new Subject<boolean>();
  newTaskCreatedSubject$ = new BehaviorSubject<number>(0);
  newTaskCreated$ = this.newTaskCreatedSubject$.asObservable();

  newTaskAttachmentDeletionSubject$: Subject<number> = new Subject<number>();
  newTaskAttachmentDeletion$ = this.newTaskAttachmentDeletionSubject$.asObservable();

  newTaskUploadSubject$: Subject<Event> = new Subject<Event>();
  newTaskUpload$ = this.newTaskUploadSubject$.asObservable();

  private allOrderTypeDictionary = new TSMap<number, EnumBase>();
  private allDamagePictureDictionary = new TSMap<number, EnumBase>();

  constructor(
    private enumsService: EnumsService,
    private httpClient: HttpClient,
    private log: LoggerService,
  ) {
  }

  getOrderTypeDesignation(orderTypes: EnumBase[], id: number) {
    if(orderTypes !== undefined && orderTypes !== null && id !== null) {
      const allOrderTypesDictionary = this.enumsService.getEnumMapSortedById(orderTypes);
      if(allOrderTypesDictionary && allOrderTypesDictionary.has(id)) {
        const { designation } = allOrderTypesDictionary.get(id);
        if(smth(designation)) {
          return designation;
        }
      }

      this.log.error({
        class: 'task.service',
        method: 'getOrderTypeDesignation',
        message: `Order Type with id:${id} not found`,
      });
    }

    return 'n/a';
  }

  getDamagePictureDesignation(damagePictures: EnumBase[], id: number) {
    if(damagePictures !== undefined && damagePictures !== null && id !== null) {
      const allDamagePicturesDictionary = this.enumsService.getEnumMapSortedById(damagePictures);
      if(allDamagePicturesDictionary && allDamagePicturesDictionary.has(id)) {
        const { designation } = allDamagePicturesDictionary.get(id);
        if(smth(designation)) {
          return designation;
        }
      }

      this.log.error({
        class: 'task.service',
        method: 'getDamagePictureDesignation',
        message: `Damage Picture with id:${id} not found`,
      });
    }

    return 'n/a';
  }

  async postNewTask(task: Task, media: MediaDataEntry[] | undefined) {
    const sirMaintenanceDetailsDto = {
      creationDate: Date.now(),
      damagePictureDescription: task.damagePictureDescription,
      damagePictureId: task.damagePictureId,
      detectorMobilePhone: task.detectorMobilePhone,
      detectorBusinessPhone: task.detectorBusinessPhone,
      detectorName: task.detectorName,
      mainGroupId: task.mainGroupId,
      orderTypeId: task.orderTypeId,
      building: task.building,
      room: task.room,
      subGroupId: task.subGroupId,
      treeNodeId: task.treeNodeId,
    };

    const createdTask = await this.httpClient
      .post<Task>(TASK_API, sirMaintenanceDetailsDto, { withCredentials: !environment.isMan && !environment.isDaimler })
      .toPromise();

    if(createdTask && media) {
      for(const attachment of media) {
        await this.httpClient
          .post<Task>(
          `${TASK_API}/${createdTask.id}/media`, attachment.formData, { withCredentials: !environment.isMan && !environment.isDaimler },
        )
          .toPromise();
      }
    }

    if(createdTask.id) {
      this.newTaskCreatedSubject$.next(createdTask.id);
      setTimeout(() => this.newTaskCreatedSubject$.next(0), 4000);
    }
  }

  async checkExistingTasks(task: Task) {
    const tasks = await this.httpClient
      .get<Task[]>(
      `${TASK_API}/detailed/?treeNodeId=${task.treeNodeId}`, { withCredentials: !environment.isMan && !environment.isDaimler },
    )
      .toPromise();

    for(const currentTask of tasks) {
      const { mainGroupId, subGroupId, orderTypeId, damagePictureId, treeNodeId } = currentTask;
      if(
        mainGroupId === task.mainGroupId
        && subGroupId === task.subGroupId
        && orderTypeId === task.orderTypeId
        && damagePictureId === task.damagePictureId
        && treeNodeId === task.treeNodeId
      ) {
        return true;
      }
    }

    return false;
  }

  getAllOrderTypesAndAllDamagePictures() {
    this.enumsService.getOrderTypes().then(value => {
      this.allOrderTypeDictionary = this.enumsService.getEnumMapSortedById(value);
    });

    this.enumsService.getDamagePictures().then(value => {
      this.allDamagePictureDictionary = this.enumsService.getEnumMapSortedById(value);
    });
  }
}
