import { createAction, props } from '@ngrx/store';
import { AppSettings, Device, EnumBase, Team } from '@core/models';

export const loadSettings = createAction(
  '[App Settings] loadSettings',
);

export const loadSettingsSuccess = createAction(
  '[App Settings] loadSettingsSuccess',
  props<{ appSettings: AppSettings }>(),
);

export const loadSettingsFail = createAction(
  '[App Settings] loadSettingsFail',
  props<{ error: string }>(),
);

export const loadDeviceSuccess = createAction(
  '[App Settings] loadDeviceSuccess',
  props<{ device: Device }>(),
);

export const loadDeviceError = createAction(
  '[App Settings] loadDeviceError',
  props<{ error: string }>(),
);

export const loadEnums = createAction(
  '[App Settings] loadEnums',
);

export const loadEnumsSuccess = createAction(
  '[App Settings] loadEnumsSuccess',
  props<{
    damagePictures: EnumBase[];
    divisions: EnumBase[];
    orderTypes: EnumBase[];
    teamGroups: Team[];
    teams: Team[];
    trades: EnumBase[];
  }>(),
);

export const loadEnumsFail = createAction(
  '[App Settings] loadEnumsFail',
  props<{ error: any }>(),
);
