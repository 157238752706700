<div [formGroup]="formGroup" [ngClass]="type" [ngStyle]="{ height: height }" class="phone_input">
  <div class="phone_input__icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="phone_input__phone">
    <mat-form-field class="phone_input__content">
      <input formControlName="{{ controlName }}" matInput placeholder="{{ label }}" type="tel" />
    </mat-form-field>
  </div>
</div>
