import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SearchParams, SuggestionsParams, TreeNodeSearch } from '../models';
import { filterEmptyValues } from '../utility';

@Injectable({ providedIn: 'any' })
export class ObjectStructureApiService {
  private readonly treeNodesApiUrl = `${environment.apiUrl}/tree-nodes`;

  constructor(private httpClient: HttpClient) {
  }

  search(term: string, options: SearchParams) {
    const fromObject = { term: (options.searchOption ?? '') + term, take: options.take, skip: options.skip };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<TreeNodeSearch[]>(
      `${this.treeNodesApiUrl}/q?`, { params, withCredentials: !environment.isMan && !environment.isDaimler },
    );
  }

  getSuggestions(term: string, options: SuggestionsParams) {
    const fromObject = { ...filterEmptyValues(options), term };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<string[]>(
      `${this.treeNodesApiUrl}/suggestions/q`, { params, withCredentials: !environment.isMan && !environment.isDaimler },
    );
  }
}
