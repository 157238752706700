import { MatDialogConfig } from '@angular/material/dialog';
import { createAction, props } from '@ngrx/store';

export const handset = createAction(
  '[App State] handset',
  props<{ handset: boolean }>(),
);

export const welcome = createAction(
  '[App State] welcome',
  props<{ welcome: boolean }>(),
);

export const filterTasks = createAction(
  '[App State] filterTasks',
  props<{ filter: number }>(),
);

export const skipTasks = createAction(
  '[App State] skipTasks',
  props<{ skip: number }>(),
);

export const newTask = createAction(
  '[App State] newTask',
  props<{ enable: boolean }>(),
);

export const ensureNewTask = createAction(
  '[App State] ensureNewTask',
);

export const newTaskButton = createAction(
  '[App State] newTaskButton',
  props<{ visible: boolean }>(),
);

export const newTaskComponent = createAction(
  '[App State] newTaskComponent',
  props<{ open: boolean }>(),
);

export const showScanner = createAction(
  '[App State] showScanner',
  props<{ config: MatDialogConfig }>(),
);

export const scanSuccess = createAction(
  '[App State] scanSuccess',
  props<{ code: string }>(),
);

export const scanCanceled = createAction(
  '[App State] scanCanceled',
  props<{ code: string }>(),
);

export const newTaskSubmit = createAction(
  '[App State] newTaskSubmit',
);

export const newTaskCancel = createAction(
  '[App State] newTaskSubmit',
);

export const loading = createAction(
  '[App State] loading',
);

export const loaded = createAction(
  '[App State] loaded',
);
