<div class="object-structure__search">
  <ng-container *ngIf="{  search: searchTerm$ | async } as c">
    <span class="search">
      <mat-form-field class="option">
        <mat-select #searchOption name="searchOption" [(ngModel)]="c.search.searchOption" (selectionChange)="onFilterKeyUp(search.value, c.search.searchOption)">
          <mat-option value="">
            <span [translate]="'OBJECT_STRUCTURE.VIA_NAME'"></span>
          </mat-option>
          <mat-option value="&">
            <span [translate]="'OBJECT_STRUCTURE.VIA_INVENTORY_NUMBER'"></span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-form-field">
        <input #search (keyup)="onFilterKeyUp(search.value, c.search.searchOption)" [(ngModel)]="c.search.term" matInput
          type="text" />
        <button (click)=onClick() *ngIf="c.search.term !== '' && c.search.term !== null" aria-label="Clear"
          mat-icon-button matSuffix>
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </span>
  </ng-container>
</div>
