<ng-container *ngIf="{searchTerm: searchTerm$ | async, viewModel: selectViewModel$ | async} as c">
  <div class="sidenav">
    <div class="sidenav__search">
      <app-search></app-search>
    </div>
    <div class="sidenav__tree" [ngClass]="c.searchTerm ? 'hidden' : 'visible'">
      <app-object-structure *ngIf="(appSettingsLoaded$ | async)" [visible]="!c.searchTerm"></app-object-structure>
    </div>
    <div class="sidenav__tree" *ngIf="c.searchTerm">
      <app-search-list
        [nodes]="c.viewModel.treeNodes"
        [canLoadMore]="c.viewModel.canLoadMore"
        (loadMore)="loadMore()"
        (selected)="selected($event)"
        [searchText]="c.searchTerm"
      ></app-search-list>
    </div>
  </div>
</ng-container>
