import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../../core/shared/material.module';
import { AlreadyExistComponent } from './already-exist/already-exist.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { GroupSelectMobileComponent } from './group-select-mobile/group-select-mobile.component';
import { GroupSelectComponent } from './group-select/group-select.component';
import { IconLabelInfoComponent } from './icon-label-info/icon-label-info.component';
import { NewTaskComponent } from './new-task/new-task.component';
import { NewTasksMobileComponent } from './new-tasks-mobile/new-tasks-mobile.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RoomInputComponent } from './room-input/room-input.component';
import { TaskCardDetailsMobileComponent } from './task-card-details-mobile/task-card-details-mobile.component';
import { TaskCardDetailsComponent } from './task-card-details/task-card-details.component';
import { TaskCardMobileComponent } from './task-card-mobile/task-card-mobile.component';
import { TaskCardComponent } from './task-card/task-card.component';
import { TaskPagerComponent } from './task-pager/task-pager.component';
import { TaskToolbarComponent } from './task-toolbar/task-toolbar.component';
import { TasksComponent } from './tasks/tasks.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';

@NgModule({
  declarations: [
    AlreadyExistComponent,
    AttachmentComponent,
    GroupSelectComponent,
    GroupSelectMobileComponent,
    IconLabelInfoComponent,
    NewTaskComponent,
    NewTasksMobileComponent,
    PhoneInputComponent,
    RoomInputComponent,
    TaskCardComponent,
    TaskCardDetailsComponent,
    TaskCardDetailsMobileComponent,
    TaskCardMobileComponent,
    TaskPagerComponent,
    TasksComponent,
    TaskToolbarComponent,
    UploadButtonComponent,
  ],
  entryComponents: [AlreadyExistComponent],
  exports: [
    IconLabelInfoComponent,
    NewTaskComponent,
    NewTasksMobileComponent,
    TaskPagerComponent,
    TasksComponent,
    TaskToolbarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class TasksModule {
}
