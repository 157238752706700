import { createSelector } from '@ngrx/store';
import { EnumBase } from '@models/enumBase';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import { EnumUtilities } from '@utility/enum-utilities';
import * as fromFeature from '../reducers';

export const selectAppSettingsState = createSelector(
  fromFeature.selectAppState,
  ({ appSettings }) => appSettings,
);

export const selectLoading = createSelector(
  selectAppSettingsState,
  ({ loading }) => loading,
);

export const selectLoaded = createSelector(
  selectAppSettingsState,
  ({ loaded }) => loaded,
);

export const selectAppSettings = createSelector(
  selectAppSettingsState,
  ({ appSettings }) => appSettings,
);

export const selectEnvironment = createSelector(
  selectAppSettingsState,
  ({ environment }) => environment,
);

export const selectDaimler = createSelector(
  selectEnvironment,
  ({ isDaimler }) => ({ daimler: isDaimler }),
);

export const selectDevice = createSelector(
  selectAppSettingsState,
  ({ device }) => device,
);

export const selectMobile = createSelector(
  selectDevice,
  device => device.deviceType === 'mobile',
);

export const selectDeviceStandalone = createSelector(
  selectDevice,
  device => device.standalone,
);

export const selectEnums = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums,
);

export const selectDamagePictures = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.damagePictures,
);

export const selectDivisions = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.divisions,
);

export const selectActiveNode = createSelector(
  fromObjectStructure.selectActiveNode,
  ({ activeNode }) => activeNode,
);

export const selectDivisionsFiltered = createSelector(
  selectDivisions,
  selectActiveNode,
  (divisions, activeNode) => {
    let ret: EnumBase[] = activeNode && activeNode.divisionId && activeNode.divisionId !== 0
      ? divisions.filter(v => v.id === activeNode.divisionId)
      : divisions;

    // Because the array is frozen in strict mode, you'll need to copy the array before sorting it
    ret = ret.slice().sort((a: EnumBase, b: EnumBase) => a.sortOrder < b.sortOrder
      ? -1
      : 1);

    ret = EnumUtilities.sort(ret);

    return ret;
  },
);

export const selectOrderTypes = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.orderTypes,
);

export const selectTeamGroups = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.teamGroups,
);

export const selectTeams = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.teams,
);

export const selectTrades = createSelector(
  selectAppSettingsState,
  ({ enums }) => enums.trades,
);

export const selectBifmNodeId = createSelector(
  selectAppSettingsState,
  ({ appSettings }) => appSettings.bifmNodeId,
);
