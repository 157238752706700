<!--suppress JSRemoveUnnecessaryParentheses -->
<ng-container *ngIf="{ handset: handset$ | async, isNew: (newTaskCreated$ | async) !== 0 } as c">
  <div class="tasks-toolbar">
    <div *ngIf="c.isNew" class="task__thank-you">
      <div class="task__thank-you-icon">
        <mat-icon>sentiment_satisfied_alt</mat-icon>
      </div>
      <div *ngIf="!c.handset" class="task__thank-you-words">{{ 'TASK.THANK_YOU' | translate }}</div>
      <div *ngIf="c.handset" class="task__thank-you-words">{{ 'TASK.THANK_YOU_MOBILE' | translate }}</div>
    </div>
    <div [hidden]="c.isNew" class="tasks__filter">
      <mat-form-field>
        <mat-select
          #filterSelector
          (selectionChange)="onSelectionChange($event)"
          [value]="preselected$ | async"
          disableOptionCentering
          panelClass="filterSelectionPanel"
        >
          <mat-select-trigger>
            {{ taskCount$ | async }}
            &nbsp;
            {{filterContentBrief[(filterIndex?.value)]?.name | translate}}
          </mat-select-trigger>
          <mat-option *ngFor="let option of filterContent; trackBy: trackById" [value]="option.id">
            <span *ngIf="filterIndex?.value === option.id">
              <mat-icon [ngClass]="'selected'">radio_button_checked</mat-icon>
              {{ option.name | translate }}
            </span>
            <span *ngIf="filterIndex?.value !== option.id"
            ><mat-icon [ngClass]="'not-selected'">radio_button_unchecked</mat-icon>
              {{ option.name | translate }}</span
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tasks__header-filler"></div>
    <button
      (click)="onNewTask()"
      *ngIf="!c.handset"
      [disabled]="(getNewTaskButton$ | async) === false"
      class="tasks__add-new-desktop"
      mat-raised-button
    >
      {{ 'TASK.NEW_TASK' | translate | uppercase }}
    </button>

    <button
      (click)="onNewTask()"
      *ngIf="c.handset"
      [disabled]="(getNewTaskButton$ | async) === false"
      class="tasks__add-new-mobile"
      mat-icon-button
    >
      <mat-icon>add_box</mat-icon>
    </button>
    <div class="tasks__header-right-filler"></div>
  </div>
</ng-container>
