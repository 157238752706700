import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { smth } from '@core/shared/extensions';
import { environment } from '@environments/environment';
import { TasksService } from '@services/task.service';
import { AppStateActions, TaskActions } from '@store/actions';
import * as fromAppSettings from '@store/selectors/app-settings.selectors';
import * as fromAppState from '@store/selectors/app-state.selectors';
import * as fromObjectStructure from '@store/selectors/object-structure.selectors';
import * as fromTasks from '@store/selectors/task.selectors';
import * as fromUser from '@store/selectors/user.selectors';
import { DivaUtility } from '@utility/diva-utility';

@Component({
  selector: 'app-task-toolbar',
  styleUrls: ['./task-toolbar.component.scss'],
  templateUrl: './task-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskToolbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('filterSelector', { static: false }) filterIndex?: MatSelect;
  getNewTaskButton$: Observable<boolean> = this.store.select(fromAppState.selectNewTaskButton);

  filterContentBrief: { id: number; name: string }[] | undefined = [];
  filterContent: { id: number; name: string }[] | undefined = [];
  preselected$ = this.store.select(fromAppState.selectFilter);

  isButtonEnabled = false;
  handset$: Observable<boolean> = this.store.select(fromAppState.selectHandset);
  newTaskCreated$ = this.taskService.newTaskCreated$;
  taskCount$ = this.store.select(fromTasks.selectCount);
  private activeNode$ = this.store.select(fromObjectStructure.selectActiveNode);
  private nodeId?: number;
  private mpuNodes?: string[];
  private minSirLevel: number;
  private appSettings$ = this.store.select(fromAppSettings.selectAppSettings);
  private currentUser$ = this.store.select(fromUser.selectUser);
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private taskService: TasksService,
  ) {
  }

  trackById = (index: number, item) => DivaUtility.trackById(index, item);

  ngOnInit() {
    this.subscription.add(this.currentUser$
      .pipe(
        filter(({ officeNode }) => officeNode !== undefined),
        tap(({ officeNode }) => {
          this.nodeId = officeNode;
        }),
      ).subscribe());

    this.subscription.add(this.appSettings$
      .pipe(
        filter(settings => settings !== undefined && smth(settings) && smth(settings.bifmNodeId)),
        tap(settings => {
          if(settings !== undefined) {
            // this.bifmNode = settings.bifmNodeId;
            // this.mpuNodes = settings.mpuNodes;
            this.minSirLevel = settings.minSirLevel;
          }
        }),
      ).subscribe());

    this.subscription.add(this.activeNode$
      .pipe(
        tap(({ activeNode }) => {
          if(activeNode && activeNode.depth) {
            this.nodeId = activeNode.id;

            if(environment.isDaimler && activeNode.attributes) {
              if(activeNode.depth >= this.minSirLevel) {
                this.processMpuScenario(activeNode.attributes);
              } else {
                this.isButtonEnabled = false;
                this.taskService.newTaskBigButtonSubject$.next(false);
              }
            } else if(activeNode.depth >= this.minSirLevel) {
              this.processMpuScenario('');
            } else {
              this.isButtonEnabled = false;
              this.taskService.newTaskBigButtonSubject$.next(false);
            }
          }
        }),
      ).subscribe());

    this.subscription.add(this.handset$
      .pipe(
        filter(value => value),
        tap(() => {
          this.filterContent = [
            { id: 0, name: 'TASK_FILTER.ALL' },
            { id: 1, name: 'TASK_FILTER.AT_WORK' },
            { id: 2, name: 'TASK_FILTER.NOT_AT_WORK' },
            { id: 3, name: 'TASK_FILTER.MINE' },
          ];

          this.filterContentBrief = [
            { id: 0, name: 'TASK_FILTER_BRIEF.ALL_MOBILE' },
            { id: 1, name: 'TASK_FILTER_BRIEF.AT_WORK_MOBILE' },
            { id: 2, name: 'TASK_FILTER_BRIEF.NOT_AT_WORK_MOBILE' },
            { id: 3, name: 'TASK_FILTER_BRIEF.MINE_MOBILE' },
          ];
        }),
      ).subscribe());

    this.subscription.add(this.handset$
      .pipe(
        filter<boolean>(value => !value),
        tap(() => {
          this.filterContent = [
            { id: 0, name: 'TASK_FILTER.ALL' },
            { id: 1, name: 'TASK_FILTER.AT_WORK' },
            { id: 2, name: 'TASK_FILTER.NOT_AT_WORK' },
            { id: 3, name: 'TASK_FILTER.MINE' },
          ];

          this.filterContentBrief = [
            { id: 0, name: 'TASK_FILTER_BRIEF.ALL' },
            { id: 1, name: 'TASK_FILTER_BRIEF.AT_WORK' },
            { id: 2, name: 'TASK_FILTER_BRIEF.NOT_AT_WORK' },
            { id: 3, name: 'TASK_FILTER_BRIEF.MINE' },
          ];
        }),
      ).subscribe());
  }

  onNewTask() {
    this.store.dispatch(AppStateActions.newTaskButton({ visible: false }));
    this.store.dispatch(AppStateActions.newTaskComponent({ open: true }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelectionChange($event: MatSelectChange) {
    this.store.dispatch(AppStateActions.filterTasks({ filter: $event.value }));
    this.store.dispatch(TaskActions.loadTasks());
  }

  ngAfterViewInit() {
    const toolbar = document.getElementById('toolbar');
    const tasks = document.getElementById('tasks');

    if(toolbar && tasks) {
      this.subscription.add(this.handset$.subscribe(isMobileView => {
        if(isMobileView) {
          toolbar.style.width = `${tasks.offsetWidth - 1}px`;
          tasks.style.paddingTop = `${toolbar.offsetHeight}px`;
        } else {
          toolbar.style.width = `${tasks.offsetWidth - 16 - 1}px`;
          tasks.style.paddingTop = `${toolbar.offsetHeight}px`;
        }
      }));

      this.subscription.add(fromEvent(window, 'resize')
        .pipe(
          map(event => (event.target as Window).innerWidth),
          distinctUntilChanged(),
        ).subscribe(() => {
          toolbar.style.width = `${tasks.offsetWidth - 1}px`;
        }));
    }
  }

  private processMpuScenario(attributes: string) {
    if(this.mpuNodes && this.mpuNodes.length === 0) {
      this.isButtonEnabled = true;
      this.taskService.newTaskBigButtonSubject$.next(true);
    } else if(this.mpuNodes && !Array.from(attributes).some(v => this.mpuNodes.includes(v))) {
      this.isButtonEnabled = true;
      this.taskService.newTaskBigButtonSubject$.next(true);
    } else {
      this.isButtonEnabled = false;
      this.taskService.newTaskBigButtonSubject$.next(false);
    }
  }
}
