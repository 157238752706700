<div class="select__details-content">
  <mat-list dense>
    <mat-list-item *ngFor="let node of nodes" class="item">
      <app-search-list-item
        [node]="node"
        (selected)="selected.emit($event)"
        [searchText]="searchText"
        class="text"
      ></app-search-list-item>
    </mat-list-item>
  </mat-list>
  <div class="d-flex flex-column mt-2 px-3">
    <button
      (click)="loadMore.emit()"
      *ngIf="canLoadMore"
      color="accent"
      mat-raised-button
    >
      {{ 'OBJECT_STRUCTURE.NEXT' | translate }}
    </button>
  </div>
</div>
