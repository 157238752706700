import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNodeSearch } from '@core/models';

@Component({
  selector: 'app-search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListItemComponent {
  @Input() node: TreeNodeSearch;
  @Input() searchText: string;

  @Output() readonly selected = new EventEmitter<number>();

  getHighlight() {
    return this.searchText
      ? this.searchText
      : null;
  }
}
