<div class="external-envelop d-flex flex-column">
  <div class="scanner">
    <app-scanner (code)="onCodeScanned($event)"></app-scanner>
  </div>

  <div class="d-flex align-items-center mt-2">
    <button *ngIf="code" class="button__info ms-2 px-3" color="warn" mat-flat-button disableRipple>{{ code }}</button>

    <button [mat-dialog-close]="undefined" class="button__cancel ms-auto me-2" color="warn" mat-fab>
      <mat-icon>close</mat-icon>
    </button>

    <button [mat-dialog-close]="code" [disabled]="!code" class="button__accept me-2" color="accent" mat-fab>
      <mat-icon>done</mat-icon>
    </button>
  </div>
</div>
